import React, { useState, useEffect } from "react";
import accounting from "accounting";
import Skeleton from "react-loading-skeleton";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardHeader,
  Table,
  Popover,
  PopoverBody,
  InputGroup,
  FormGroup,
} from "reactstrap";
import { useMst } from "store/RootStore";
import { useHistory } from "react-router";
import { RateType } from "types";
import { toast } from "react-toastify";
import config from "../../../../config";
import { getRateDescription } from "utils/helpers";
import classNames from "classnames";

const ApprovalsList: React.FC = () => {
  const store = useMst();
  const history = useHistory();
  const [pageLoading, setPageLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedApprovePopover, setSelectedApprovePopover] = useState("");
  const [selectedRejectPopover, setSelectedRejectPopover] = useState("");
  const [remarks, setRemarks] = useState("");
  const [selectedYear, setSelectedYear] = useState("2024");
  const [selectedPeriod, setSelectedPeriod] = useState("1");

  const [rates, setRates] = useState<RateType[]>([]);

  useEffect(() => {
    setPageLoading(true);

    store.userAccount
      .getMyApprovalsList(selectedYear, selectedPeriod)
      .then((resp) => resp.data.data)
      .then((data) => setRates(data))
      .then(() => setPageLoading(false));
  }, [selectedYear, selectedPeriod]);

  const handleApproveAll = async () => {
    try {
      setIsSubmitting(true);
      await store.userAccount.approveAll();
      setIsSubmitting(false);
      //   history.push("/admin/approvals/approved/list");
      window.location.reload();
    } catch (e) {
      setIsSubmitting(false);
      toast.error("Error while trying to approve rate");
      console.log('Catch Approve All Error', e);
    }
  };

  const handleApprove = async (uuid: string) => {
    try {
      setIsSubmitting(true);
      await store.userAccount.approveRate(uuid);
      setIsSubmitting(false);
      //   history.push("/admin/approvals/approvals/list");
      window.location.reload();
    } catch (e) {
      setIsSubmitting(false);
      toast.error("Error while trying to approve rate");
      console.log('Catch Approve Error', e);
    }
  };

  const handleReject = async (uuid: string) => {
    try {
      setIsSubmitting(true);
      await store.userAccount.rejectRate(uuid, remarks);
      window.location.reload();
      setIsSubmitting(false);
    } catch (e) {
      setIsSubmitting(false);
      toast.success("Error while trying to approve rate");
      console.log('Catch Reject Error', e);
    }
  };

  return (
    <>
      <div className="header bg-default pb-6">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col lg="12" xs="12">
                <div className="d-flex justify-content-between">
                  <h6 className="h2 text-white d-inline-block mb-0">
                    For Approvals
                  </h6>
                  {rates.length ? (
                    <button
                      disabled={isSubmitting}
                      className="btn btn-primary"
                      onClick={() => handleApproveAll()}
                    >
                      {isSubmitting ? "Please wait..." : "Approve all"}
                    </button>
                  ) : null}
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>

      <Container className="mt--6" fluid>
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <h5 className="h3 mb-0"></h5>
                <Row>
                  <Col lg="6" xs="7">
                    <FormGroup>
                      <label className="form-control-label">Year</label>
                      <InputGroup className={classNames("input-group-merge")}>
                        <select
                          className="form-control"
                          value={selectedYear}
                          onChange={(e) => setSelectedYear(e.target.value)}
                        >
                          <option value="2024">2024</option>
                          <option value="2023">2023</option>
                          <option value="2022">2022</option>
                          <option value="2021">2021</option>
                          <option value="2020">2020</option>
                        </select>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col lg="6" xs="7">
                    <FormGroup>
                      <label className="form-control-label">Period</label>
                      <InputGroup className={classNames("input-group-merge")}>
                        <select
                          className="form-control"
                          value={selectedPeriod}
                          onChange={(e) => setSelectedPeriod(e.target.value)}
                        >
                          <option value="1">First</option>
                          <option value="2">Second</option>
                        </select>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
              </CardHeader>

              <CardBody>
                {pageLoading ? (
                  <Skeleton count={5}></Skeleton>
                ) : rates.length ? (
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th className="sort" data-sort="name" scope="col">
                          Store
                        </th>
                        <th className="sort" data-sort="name" scope="col">
                          Year
                        </th>
                        <th className="sort" data-sort="name" scope="col">
                          Period
                        </th>
                        <th
                          className="sort"
                          data-sort="name"
                          scope="col"
                          style={{ width: 80 }}
                        >
                          Rater
                        </th>
                        <th
                          className="sort"
                          data-sort="name"
                          scope="col"
                          style={{ width: 80 }}
                        >
                          Employee name
                        </th>
                        <th
                          className="sort"
                          data-sort="name"
                          scope="col"
                          style={{ width: 200 }}
                        >
                          Form Template
                        </th>
                        <th className="sort" data-sort="name" scope="col">
                          Group details
                        </th>
                        <th className="sort" data-sort="name" scope="col">
                          Overall Score
                        </th>
                        <th className="sort" data-sort="name" scope="col">
                          Overall Rating
                        </th>
                        <th className="sort" data-sort="name" scope="col">
                          Status
                        </th>
                        <th scope="col" />
                      </tr>
                    </thead>
                    <tbody className="list">
                      {rates.map((rate) => {
                        return (
                          <tr key={rate.uuid}>
                            <td>{rate.rateeUser?.profile?.storeNumber}</td>
                            <td>{rate.year}</td>
                            <td>
                              {rate.period == "1"
                                ? "First Period"
                                : "Second Period"}
                            </td>
                            <td>
                              {rate.rater?.profile?.firstName}{" "}
                              {rate.rater?.profile?.lastName}
                            </td>
                            <td>
                              {rate.rateeUser?.profile?.firstName}{" "}
                              {rate.rateeUser?.profile?.lastName}
                            </td>
                            <td>{rate.name}</td>
                            <td>
                              <Table size="sm" borderless>
                                {rate.groups.map((group, index) => (
                                  <tr key={index}>
                                    <td>
                                      {group.name} (
                                      {accounting.formatNumber(group.weight)}%)
                                    </td>
                                    <td>
                                      <b>{group.totalScore}</b>
                                    </td>
                                  </tr>
                                ))}
                              </Table>
                            </td>
                            <td>
                              <b>{rate.totalScore}</b>
                            </td>
                            <td>
                              <b>{getRateDescription(rate.totalScore)}</b>
                            </td>
                            <td>{config.rateStatusesLabels[rate.status]}</td>
                            <td>
                              <Table size="sm" borderless>
                                <tr>
                                  <td>
                                    <Button
                                      className="btn-default"
                                      onClick={(e) =>
                                        history.push(
                                          `/admin/rate_employee/${rate.uuid}/details`
                                        )
                                      }
                                      size="sm"
                                    >
                                      View
                                    </Button>
                                    <Popover
                                      placement="top"
                                      target={`btn-approve-${rate.uuid}`}
                                      isOpen={
                                        selectedApprovePopover ==
                                        `btn-approve-${rate.uuid}`
                                      }
                                      className="popover-default"
                                      toggle={() =>
                                        setSelectedApprovePopover(
                                          `btn-approve-${rate.uuid}`
                                        )
                                      }
                                    >
                                      <PopoverBody>
                                        <p className="text-dark font-weight-bold">
                                          Are you sure you want to approve this?
                                        </p>
                                        <Button
                                          className="btn btn-default"
                                          disabled={isSubmitting}
                                          size="sm"
                                          onClick={() =>
                                            rate.uuid &&
                                            handleApprove(rate.uuid)
                                          }
                                        >
                                          {isSubmitting
                                            ? "Please wait..."
                                            : "Approve"}
                                        </Button>
                                        <Button
                                          className="btn btn-primary"
                                          size="sm"
                                          onClick={() =>
                                            setSelectedApprovePopover("")
                                          }
                                        >
                                          No, cancel
                                        </Button>
                                      </PopoverBody>
                                    </Popover>
                                  </td>
                                </tr>

                                <tr>
                                  <td>
                                    <Button
                                      id={`btn-approve-${rate.uuid}`}
                                      className="btn-primary"
                                      size="sm"
                                    >
                                      Approve
                                    </Button>
                                    <Popover
                                      placement="top"
                                      isOpen={
                                        selectedRejectPopover ==
                                        `btn-reject-${rate.uuid}`
                                      }
                                      target={`btn-reject-${rate.uuid}`}
                                      toggle={() =>
                                        setSelectedRejectPopover(
                                          `btn-reject-${rate.uuid}`
                                        )
                                      }
                                    >
                                      <PopoverBody>
                                        <p className="text-dark font-weight-bold">
                                          Are you sure you want reject? Please
                                          leave your remarks
                                        </p>
                                        <input
                                          type="text"
                                          className="form-control mb-3"
                                          value={remarks}
                                          onChange={(e) =>
                                            setRemarks(e.target.value)
                                          }
                                        />
                                        <Button
                                          className="btn btn-danger"
                                          size="sm"
                                          onClick={() =>
                                            rate.uuid && handleReject(rate.uuid)
                                          }
                                        >
                                          Reject
                                        </Button>
                                        <Button
                                          className="btn btn-primary"
                                          size="sm"
                                          onClick={() => {
                                            setRemarks("");
                                            setSelectedRejectPopover("");
                                          }}
                                        >
                                          No, cancel
                                        </Button>
                                      </PopoverBody>
                                    </Popover>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <Button
                                      id={`btn-reject-${rate.uuid}`}
                                      className="btn-danger"
                                      size="sm"
                                    >
                                      Reject
                                    </Button>
                                  </td>
                                </tr>
                              </Table>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                ) : (
                  <div
                    className="d-flex justify-content-center flex-column align-items-center"
                    style={{ height: 500 }}
                  >
                    <img
                      style={{ width: 300 }}
                      className="mb-5"
                      src="https://repairs-and-maintenance-public.s3-ap-southeast-1.amazonaws.com/undraw_forms_78yw.svg"
                    />
                    <h2 className="mb-2">No rates to approve for now.</h2>
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ApprovalsList;
