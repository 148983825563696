import React, { useState, useEffect } from "react";
import accounting from "accounting";
import Skeleton from "react-loading-skeleton";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardHeader,
  Table,
  UncontrolledPopover,
  PopoverBody,
} from "reactstrap";
import { useMst } from "store/RootStore";
import { useHistory, useParams } from "react-router";
import { RateType } from "types";
import { toast } from "react-toastify";
import config from "../../../../config";

const ShowRate: React.FC = () => {
  const store = useMst();
  const history = useHistory();
  const { id } = useParams();
  const [pageLoading, setPageLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [rate, setRate] = useState<RateType | null>(null);

  useEffect(() => {
    setPageLoading(true);
    store.userAccount
      .getRate(id)
      .then((res) => res.data.data)
      .then((data) => setRate(data))
      .catch((e) => console.log(e))
      .then(() => setPageLoading(false));
  }, [id]);

  const isIDP = rate && rate.totalScore >= 3;

  const handleApprove = async () => {
    try {
      setIsSubmitting(true);
      const uuid = rate?.uuid;
      await store.userAccount.approveRate(uuid);
      toast.success("Successfully approved rate");
      history.push("/admin/approvals/approved/list");
      setIsSubmitting(false);
    } catch (e) {
      setIsSubmitting(false);
      toast.error("Error while trying to approve rate");
    }
  };

  const handleConvertSelfRate = async () => {
    try {
      setIsSubmitting(true);
      const uuid = rate?.uuid;
      await store.userAccount.convertSelfRate(uuid);
      toast.success("Successfully created new rate from the given self-rate");
      history.push("/admin/rates/list");
      setIsSubmitting(false);
    } catch (e) {
      setIsSubmitting(false);
      toast.error("Error while trying to use self-rate");
    }
  };

  return (
    <>
      <div className="header bg-default pb-6">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col lg="6" xs="7">
                <h6 className="h2 text-white d-inline-block mb-0">
                  Rate Details
                </h6>
              </Col>
              <Col className="text-right" lg="6" xs="5">
                {!rate?.isSelfRate &&
                rate?.approver?.id === store.userAccount.user?.id &&
                rate?.status === 5 ? (
                  <Button
                    className="btn btn-primary"
                    onClick={() => handleApprove()}
                  >
                    {isSubmitting ? "Processing..." : "Approve"}
                  </Button>
                ) : null}

                {rate?.isSelfRate &&
                rate?.actualApprover?.id === store.userAccount.user?.id ? (
                  <Button
                    disabled={isSubmitting}
                    className="btn btn-primary"
                    onClick={() => handleConvertSelfRate()}
                  >
                    {isSubmitting ? "Please wait..." : "Use this self-rate"}
                  </Button>
                ) : null}
              </Col>
            </Row>
          </div>
        </Container>
      </div>

      <Container className="mt--6" fluid>
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <h5 className="h3 mb-0">
                  {rate?.name} - {rate?.year}
                </h5>
              </CardHeader>
              {pageLoading ? (
                <CardBody>
                  <Skeleton count={5} />
                </CardBody>
              ) : (
                <CardBody>
                  <Table>
                    <tbody>
                      <tr>
                        <td style={{ width: 140 }}>
                          <b>Ratee</b>
                        </td>
                        <td style={{ width: 300 }}>
                          {rate?.rateeUser?.profile?.firstName}{" "}
                          {rate?.rateeUser?.profile?.lastName}
                        </td>
                        <td style={{ width: 140 }}>
                          <b>Ratee Dept</b>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td style={{ width: 140 }}>
                          <b>Ratee's Position</b>
                        </td>
                        <td style={{ width: 300 }}>
                          {rate?.rateeUser?.profile?.position}
                        </td>
                        <td>
                          <b>Ratee's Employee No</b>
                        </td>
                        <td>{rate?.rateeUser?.profile?.employeeNumber}</td>
                      </tr>
                      <tr>
                        <td>
                          <b>Ratee's Division</b>
                        </td>
                        <td></td>
                        <td>
                          <b>Period</b>
                        </td>
                        <td>
                          {rate?.period == "1" ? "First" : "Second"} (
                          {rate?.year})
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                  <hr />
                  <Table>
                    <tbody>
                      <tr>
                        <td style={{ width: 140 }}>
                          <b>Rater</b>
                        </td>
                        <td style={{ width: 300 }}>
                          {rate?.rater?.profile?.firstName}{" "}
                          {rate?.rater?.profile?.lastName}
                        </td>
                        <td style={{ width: 140 }}>
                          <b>Rater Dept</b>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td style={{ width: 140 }}>
                          <b>Rater Position</b>
                        </td>
                        <td style={{ width: 300 }}>
                          {rate?.rater?.profile?.position}
                        </td>
                        <td>
                          <b>Rater Employee No</b>
                        </td>
                        <td>{rate?.rater?.profile?.employeeNumber}</td>
                      </tr>
                      <tr>
                        <td>
                          <b>Rater Division</b>
                        </td>
                        <td></td>
                        <td>
                          <b>Period</b>
                        </td>
                        <td>
                          {rate?.period == "1" ? "First" : "Second"} (
                          {rate?.year})
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </CardBody>
              )}
            </Card>
            {rate?.groups.map((group, groupIndex) => (
              <Card key={group.name}>
                <CardHeader>
                  <h5 className="h3 mb-0">{group.name}</h5>
                </CardHeader>
                <CardBody>
                  <Table className="align-items-center" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th>Description</th>
                        <th>Weight</th>
                        <th>Items</th>
                        <th>Score</th>
                        <th>Remarks</th>
                        {groupIndex == 0 ? <th>IDP</th> : null}
                      </tr>
                    </thead>
                    <tbody>
                      {group.items.map((item, itemIndex) => {
                        return (
                          <tr key={itemIndex}>
                            <td>{item.name}</td>
                            <td>{item.weight}%</td>
                            <td style={{ height: 1 }}>
                              <div
                                className="d-flex flex-column h-100 justify-content-around word-wrap"
                                style={{ maxWidth: 380 }}
                              >
                                {item.subitems.map((subitem, index) => (
                                  <div key={index}>{subitem.name}</div>
                                ))}
                              </div>
                            </td>
                            <td style={{ height: 1 }}>
                              {item.subitems.map((subitem, index) => (
                                <div
                                  key={index}
                                  className="font-weight-bold mb-3"
                                >
                                  {subitem.score}
                                </div>
                              ))}
                            </td>
                            <td style={{ height: 1 }}>
                              {item.subitems.map((subitem, index) => (
                                <div
                                  key={index}
                                  className="font-weight-bold mb-3"
                                >
                                  {subitem.remarks}
                                </div>
                              ))}
                            </td>
                            {groupIndex == 0 ? (
                              <td style={{ height: 1 }}>
                                {item.subitems.map((subitem, index) => (
                                  <div
                                    key={index}
                                    className="font-weight-bold mb-3"
                                  >
                                    {subitem.idpAssessment?.competencyGap
                                      ? "With competency gap"
                                      : "No competency gap"}{" "}
                                    - {subitem.idpAssessment?.remarks}
                                  </div>
                                ))}
                              </td>
                            ) : null}
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            ))}

            {rate &&
            !rate.isSelfRate &&
            isIDP &&
            rate.type == config.formHeaderTypes.WITH_IDP_PIP ? (
              <Card>
                {rate.idpDevelopmentPlan.length ? (
                  <>
                    <CardHeader>
                      <div className="d-flex justify-content-between">
                        <span>Individual Development Plan</span>

                        {rate?.rater?.id === store.userAccount.user?.id ? (
                          <Button
                            onClick={() =>
                              history.push(`/admin/rate_employee/${id}/idp`)
                            }
                            className="btn-default"
                          >
                            Edit IDP details
                          </Button>
                        ) : null}
                      </div>
                    </CardHeader>
                    <CardBody>
                      <div>
                        <Table>
                          <thead>
                            <tr>
                              <th>PRIORITY KRAS FOR DEVELOPMENT</th>
                              <th>SELECT APPLICABLE INTERVENTIONS </th>
                              <th>DETAILS</th>
                              <th>COMPLETION DATE</th>
                            </tr>
                          </thead>
                          <tbody>
                            {rate.idpDevelopmentPlan.map((plan, planIndex) => {
                              return (
                                <tr key={planIndex}>
                                  <td>
                                    {plan.developmentPlanType
                                      ? config.developmentPlans[
                                          plan.developmentPlanType
                                        ]
                                      : null}
                                  </td>
                                  <td>
                                    {plan.interventions.map(
                                      (intervention, iIndex) => (
                                        <div key={iIndex}>
                                          {intervention.interventionType
                                            ? config.interventions[
                                                intervention.interventionType
                                              ]
                                            : null}
                                        </div>
                                      )
                                    )}
                                  </td>
                                  <td>
                                    {plan.interventions.map(
                                      (intervention, iIndex) => (
                                        <div key={iIndex}>
                                          {intervention.remarks}
                                        </div>
                                      )
                                    )}
                                  </td>
                                  <td>
                                    {plan.interventions.map(
                                      (intervention, iIndex) => (
                                        <div key={iIndex}>
                                          {intervention.completionDate}
                                        </div>
                                      )
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </div>
                    </CardBody>
                  </>
                ) : (
                  <div
                    className="d-flex justify-content-center flex-column align-items-center"
                    style={{ height: 500 }}
                  >
                    <img
                      style={{ width: 300 }}
                      className="mb-5"
                      src="https://repairs-and-maintenance-public.s3-ap-southeast-1.amazonaws.com/undraw_forms_78yw.svg"
                    />
                    <h2 className="mb-2">No IDP defined yet.</h2>
                    {rate?.rater?.id === store.userAccount.user?.id ? (
                      <button
                        className="btn btn-primary"
                        onClick={() =>
                          history.push(`/admin/rate_employee/${id}/idp`)
                        }
                      >
                        Create IDP for this PMS
                      </button>
                    ) : null}
                  </div>
                )}
              </Card>
            ) : null}

            {rate &&
            !rate.isSelfRate &&
            !isIDP &&
            rate.type == config.formHeaderTypes.WITH_IDP_PIP ? (
              <Card>
                {rate.pipImprovementPlan.length ? (
                  <>
                    <CardHeader>
                      <div className="d-flex justify-content-between">
                        <h2>Personal Improvement Plan</h2>

                        {rate?.rater?.id === store.userAccount.user?.id ? (
                          <Button
                            onClick={() =>
                              history.push(`/admin/rate_employee/${id}/pip`)
                            }
                            className="btn-default"
                          >
                            Edit PIP details
                          </Button>
                        ) : null}
                      </div>
                    </CardHeader>
                    <CardBody>
                      <div>
                        <Table>
                          <thead>
                            <tr>
                              <th>AREAS FOR IMPROVEMENT</th>
                              <th>CURRENT PERFORMANCE</th>
                              <th>TARGET PERFORMANCE</th>
                              <th>SELECT APPLICABLE INTERVENTIONS </th>
                              <th>DETAILS</th>
                              <th>COMPLETION DATE</th>
                            </tr>
                          </thead>
                          <tbody>
                            {rate.pipImprovementPlan.map((plan, planIndex) => (
                              <tr key={planIndex}>
                                <td>
                                  {plan.improvementPlanType
                                    ? config.developmentPlans[
                                        plan.improvementPlanType
                                      ]
                                    : null}
                                </td>
                                <td>{plan.currentPerformance}</td>
                                <td>{plan.targetPerformance}</td>
                                <td>
                                  {plan.interventions.map(
                                    (intervention, iIndex) => (
                                      <div key={iIndex}>
                                        {
                                          config.interventions[
                                            intervention.interventionType
                                          ]
                                        }
                                      </div>
                                    )
                                  )}
                                </td>
                                <td>
                                  {plan.interventions.map(
                                    (intervention, iIndex) => (
                                      <div key={iIndex}>
                                        {intervention.remarks}
                                      </div>
                                    )
                                  )}
                                </td>
                                <td>
                                  {plan.interventions.map(
                                    (intervention, iIndex) => (
                                      <div key={iIndex}>
                                        {intervention.completionDate}
                                      </div>
                                    )
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    </CardBody>
                  </>
                ) : (
                  <div
                    className="d-flex justify-content-center flex-column align-items-center"
                    style={{ height: 500 }}
                  >
                    <img
                      style={{ width: 300 }}
                      className="mb-5"
                      src="https://repairs-and-maintenance-public.s3-ap-southeast-1.amazonaws.com/undraw_forms_78yw.svg"
                    />
                    <h2 className="mb-2">No PIP defined yet.</h2>
                    {rate?.rater?.id === store.userAccount.user?.id ? (
                      <button
                        className="btn btn-primary"
                        onClick={() =>
                          history.push(`/admin/rate_employee/${id}/pip`)
                        }
                      >
                        Create PIP for this PMS
                      </button>
                    ) : null}
                  </div>
                )}
              </Card>
            ) : null}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ShowRate;
