import React, { useState } from "react";
import { get } from "lodash";
import { Controller, useFormContext, useFieldArray } from "react-hook-form";
import classnames from "classnames";
import PropTypes from "prop-types";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  InputGroup,
  Collapse,
  Button,
} from "reactstrap";

import { FormTemplateInput } from "../../../../../types";
import FormTemplateItem from "./FormTemplateItem";

interface Props {
  index: number;
  onRemove: () => void;
}

const FormTemplateGroup: React.FC<Props> = (props) => {
  const { index, onRemove } = props;

  const form = useFormContext<FormTemplateInput>();
  const errors = form.errors;
  const [collapseOpen, setCollapseOpen] = useState(true);

  const control = form.control;

  const { fields, append, remove } = useFieldArray({
    control,
    name: `groups[${index}].items`,
  });

  const handleRemove = (i: number): void => {
    remove(i);
  };

  return (
    <div className="accordion">
      <Row key={index}>
        <Col l="12">
          <Card>
            <CardHeader
              role="tab"
              onClick={() => setCollapseOpen(!collapseOpen)}
            >
              <h5 className="h3 mb-0 d-flex justify-content-between">
                <span>Form Group #{index + 1}</span>
                {index > 0 ? (
                  <Button
                    className="btn btn-danger mr-3"
                    size="sm"
                    onClick={(e) => {
                      e.preventDefault();
                      onRemove();
                    }}
                  >
                    Delete
                  </Button>
                ) : null}
              </h5>
            </CardHeader>
            <Collapse role="tabpanel" isOpen={collapseOpen}>
              <CardBody>
                <FormGroup>
                  <label className="form-control-label">Name</label>
                  <InputGroup className={classnames("input-group-merge")}>
                    <Controller
                      name={`groups[${index}].name`}
                      control={form.control}
                      as={
                        <Input
                          invalid={!!get(errors, `groups[${index}].name`)}
                          placeholder="e.g. Behavioral"
                          type="text"
                        />
                      }
                    />
                  </InputGroup>
                  {get(errors, `groups[${index}].name`) ? (
                    <span className="text-danger mt-3 d-block">
                      {get(errors, `groups[${index}].name`).message}
                    </span>
                  ) : null}
                </FormGroup>
                <FormGroup>
                  <label className="form-control-label">Weight</label>
                  <InputGroup className={classnames("input-group-merge")}>
                    <Controller
                      name={`groups[${index}].weight`}
                      control={form.control}
                      as={
                        <Input
                          invalid={!!get(errors, `groups[${index}].weight`)}
                          placeholder=""
                          type="text"
                        />
                      }
                    />
                  </InputGroup>
                  {get(errors, `groups[${index}].weight`) ? (
                    <span className="text-danger mt-3 d-block">
                      {get(errors, `groups[${index}].weight`).message}
                    </span>
                  ) : null}
                </FormGroup>

                <div className="d-flex">
                  <FormGroup className="flex-grow-1 mr-1">
                    <label className="form-control-label">Subtitle</label>
                    <InputGroup className={classnames("input-group-merge")}>
                      <Controller
                        name={`groups[${index}].subtitle`}
                        control={form.control}
                        as={
                          <Input
                            placeholder="Leave blank if not necessary"
                            type="text"
                          />
                        }
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup className="flex-grow-1 ml-1">
                    <label className="form-control-label">Subitem label</label>
                    <InputGroup className={classnames("input-group-merge")}>
                      <Controller
                        name={`groups[${index}].subitemLabel`}
                        control={form.control}
                        as={
                          <Input
                            placeholder="Leave blank if not necessary"
                            type="text"
                          />
                        }
                      />
                    </InputGroup>
                  </FormGroup>
                </div>
                <hr />
                <FormGroup>
                  <label className="form-control-label mb-4 d-flex justify-content-between">
                    <span>Items</span>
                    <Button
                      className="btn-default"
                      size="sm"
                      onClick={() =>
                        append({
                          name: "",
                          weight: 0,
                          maxScore: 5,
                        })
                      }
                    >
                      Add Items
                    </Button>
                  </label>
                  {fields.map((item, itemIndex) => {
                    return (
                      <FormTemplateItem
                        key={item.id}
                        groupIndex={index}
                        index={itemIndex}
                        onRemove={() => handleRemove(itemIndex)}
                      />
                    );
                  })}
                </FormGroup>
              </CardBody>
            </Collapse>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

FormTemplateGroup.propTypes = {
  index: PropTypes.number.isRequired,
  onRemove: PropTypes.any.isRequired,
};

export default FormTemplateGroup;
