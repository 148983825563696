import React from 'react';
  
export default function Pagination(props : any) {
  
    function getClassName(active : any, current : any) {
        if(active == current) {
            return "btn-default btn btn-secondary btn-sm text-white";
        } else{
            return "btn-primary btn btn-secondary btn-sm";
        }
    }

    const arrays = [];

    for (let a = 0; a < props.links.last_page; a++) {
        arrays.push(a + 1);
    }

    return (
        
            <div className="mb-4">
                <div className="flex flex-wrap mt-8">
                    {arrays.map((link : any) => (
                        
                            link.url === null ?
                                    (<div
                                            className="mr-1 mb-1 px-4 py-3 text-sm leading-4 text-gray-400 border rounded"
                                            dangerouslySetInnerHTML={{ __html: link }} />) :
  
                                    (<a type="button"
                                                className={getClassName(props.links.current_page, link)}
                                                // href={`?page=${link}`}
                                                onClick={() => props.handleClick(link)}
                                                dangerouslySetInnerHTML={{ __html: link }} />)
                                    ))}
                </div>
            </div>
         
    );
}