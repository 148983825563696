import React, { useState, useEffect } from "react";
import {
  Route,
  Switch,
  Redirect,
  RouteComponentProps,
  useHistory,
} from "react-router-dom";
import AdminNavbar from "components/Navbars/AdminNavbar";
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar";

import routes from "routes";
import { AppRoutes, AppRouteCollection, AppRouteView } from "types";
import { ToastContainer } from "react-toastify";

export type RoutesCollection = (Route | null)[];
export type RouteResponse = (RoutesCollection | Route | null)[];

const Admin: React.FC = (props) => {
  const [sidenavOpen, setSidenavOpen] = useState(true);
  const history = useHistory();
  const pathname = history.location.pathname;

  useEffect(() => {
    // document.body.classList.add("bg-default");
    document.body.classList.remove("bg-default");
  }, []);

  const getRoutes = (routes: AppRoutes): any => {
    return routes.map((prop, key) => {
      const route = prop as AppRouteCollection;
      if (route.collapse) {
        return getRoutes(route.views);
      }

      const routeView = prop as AppRouteView;
      if (routeView.layout === "/admin") {
        return (
          <Route
            path={routeView.layout + routeView.path}
            component={routeView.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  const getBrandText = (path: string) => {
    for (let i = 0; i < routes.length; i++) {
      const route = routes[i] as AppRouteView;
      if (pathname.indexOf(route.layout + route.path) !== -1) {
        return routes[i].name;
      }
    }
    return "Brand";
  };

  const toggleSidenav = (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
    }
    setSidenavOpen(sidenavOpen);
  };

  const getNavbarTheme = () => {
    return pathname.indexOf("admin/alternative-dashboard") === -1
      ? "dark"
      : "light";
  };

  const closeSidenav = () => {
    console.log("Close sidenav");
  };

  return (
    <>
      <Sidebar
        {...props}
        routes={routes}
        toggleSidenav={toggleSidenav}
        sidenavOpen={sidenavOpen}
        logo={{
          innerLink: "/",
          imgSrc: "",
          imgAlt: "...",
        }}
      />
      <div className="main-content" onClick={closeSidenav}>
        <AdminNavbar
          {...props}
          theme={getNavbarTheme()}
          toggleSidenav={toggleSidenav}
          sidenavOpen={sidenavOpen}
        />
        <Switch>
          {getRoutes(routes)}
          <Redirect from="*" to="/admin/dashboard" />
        </Switch>
      </div>
      <ToastContainer />
      {sidenavOpen ? (
        <div className="backdrop d-xl-none" onClick={toggleSidenav} />
      ) : null}
    </>
  );
};

export default Admin;
