import React, { useState } from "react";
import localforage from "localforage";
import classnames from "classnames";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import AuthHeader from "components/Headers/AuthHeader";
import { useMst } from "store/RootStore";
import { useHistory } from "react-router";
import { UserAccountType } from "types";

import config from "../../../config";
import { getSnapshot } from "mobx-state-tree";
import { toast, ToastContainer } from "react-toastify";

const AffirmPage: React.FC = () => {
  const store = useMst();
  const history = useHistory();
  return (
    <>
      <Container className="mt-8 pb-5">
        <Row className="justify-content-center">
          <Col lg="8" md="8">
            <Card className="bg-secondary border-0 mb-0">
              <CardBody className="d-flex flex-column justify-content-center align-items-center p-3">
                <img
                  style={{ width: "80%" }}
                  src="https://repairs-and-maintenance-public.s3-ap-southeast-1.amazonaws.com/undraw_celebration_0jvk.svg"
                ></img>
                <h3 className="mt-4">
                  You have acknowledged your PMS. Thanks!
                </h3>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <ToastContainer />
      </Container>
    </>
  );
};

export default AffirmPage;
