import { RateGroupType } from "./../../types";
import { types, applySnapshot, cast } from "mobx-state-tree";
import config from "../../config";

import { User } from "./User";

export const PIPImprovementPlanEvaluation = types
  .model({
    id: types.maybe(types.number),
    objective: types.optional(types.string, ""),
    rating: types.optional(types.string, ""),
    remarks: types.optional(types.string, ""),
  })
  .actions((self) => ({
    setObjective(v: string) {
      self.objective = v;
    },
    setRating(v: string) {
      self.rating = v;
    },
    setRemarks(v: string) {
      self.remarks = v;
    },
  }));

export const PIPImprovementPlanMonthlyEvaluation = types
  .model({
    id: types.maybe(types.number),
    monthNumber: types.number,
    evaluations: types.optional(types.array(PIPImprovementPlanEvaluation), [
      {},
      {},
      {},
    ]),
  })
  .actions((self) => ({
    setMonthNumber(v: number) {
      self.monthNumber = v;
    },
  }));

export const PIPImprovementPlanIntervention = types
  .model({
    id: types.maybe(types.number),
    interventionType: types.optional(types.string, ""),
    remarks: types.optional(types.string, ""),
    completionDate: types.optional(types.string, ""),
  })
  .actions((self) => ({
    setInterventionType(v: string) {
      self.interventionType = v;
    },
    setRemarks(v: string) {
      self.remarks = v;
    },
    setCompletionDate(v: string) {
      self.completionDate = v;
    },
  }));

export const PIPImprovementPlan = types
  .model({
    id: types.maybe(types.number),
    improvementPlanType: types.maybeNull(types.string),
    currentPerformance: types.optional(types.number, 0),
    targetPerformance: types.optional(types.number, 0),
    interventions: types.optional(types.array(PIPImprovementPlanIntervention), [
      {},
      {},
      {},
    ]),
    monthlyEvaluations: types.optional(
      types.array(PIPImprovementPlanMonthlyEvaluation),
      [{ monthNumber: 1 }, { monthNumber: 2 }, { monthNumber: 3 }]
    ),
  })
  .actions((self) => ({
    setImprovementPlanType(v: string) {
      self.improvementPlanType = v;
    },
    setCurrentPerformance(v: number) {
      self.currentPerformance = v;
    },
    setTargetPerformance(v: number) {
      self.targetPerformance = v;
    },
  }));

export const IDPSubitemAssessment = types
  .model({
    id: types.maybe(types.number),
    competencyGap: types.optional(types.boolean, false),
    remarks: types.optional(types.string, ""),
  })
  .actions((self) => ({
    setCompetencyGap(v: boolean) {
      self.competencyGap = v;
    },
    setRemarks(v: string) {
      self.remarks = v;
    },
  }));

export const IDPDevelopmentPlanIntervention = types
  .model({
    id: types.maybe(types.number),
    interventionType: types.maybeNull(types.string),
    remarks: types.optional(types.string, ""),
    completionDate: types.optional(types.string, ""),
  })
  .actions((self) => ({
    setInterventionType(v: string) {
      self.interventionType = v;
    },
    setRemarks(v: string) {
      self.remarks = v;
    },
    setCompletionDate(v: string) {
      self.completionDate = v;
    },
  }));

export const IDPDevelopmentPlan = types
  .model({
    id: types.maybe(types.number),
    developmentPlanType: types.maybeNull(types.string),
    interventions: types.optional(types.array(IDPDevelopmentPlanIntervention), [
      {},
      {},
      {},
    ]),
  })
  .actions((self) => ({
    setDevelopmentPlanType(v: string) {
      self.developmentPlanType = v;
    },
    setInterventions(count: number) {
      for (let i = 0; i < count; i++) {
        self.interventions.push({});
      }
    },
  }));

export const RateSubitemScoreSubChoice = types
  .model({
    name: types.string,
    subtitle: types.string,
    description: types.string,
    score: types.number,
    selected: types.boolean,
  })
  .actions((self) => ({
    setSelected(v: boolean) {
      self.selected = v;
    },
  }));

export const RateSubitemScoreChoice = types
  .model({
    name: types.string,
    subtitle: types.string,
    description: types.string,
    score: types.number,
    subitemScoreSubChoices: types.array(RateSubitemScoreSubChoice),
  })
  .actions((self) => ({
    setScore(v: number) {
      self.score = v;
    },
    setSubchoiceSelected(index: number) {
      self.subitemScoreSubChoices.map((subChoice, i) => {
        if (index == i) {
          return subChoice.setSelected(true);
        }
        return subChoice.setSelected(false);
      });
    },
  }));

export const RateSubitem = types
  .model({
    id: types.maybe(types.number),
    name: types.string,
    choiceInputType: types.number,
    weight: types.number,
    editable: types.boolean,
    subitemScoreChoices: types.array(RateSubitemScoreChoice),

    idpAssessment: types.optional(types.maybeNull(IDPSubitemAssessment), {}),

    score: types.number,
    remarks: types.string,
  })
  .actions((self) => ({
    setScore(v: number) {
      self.score = v;
    },
    setRemarks(v: string) {
      self.remarks = v;
    },
    setName(v: string) {
      self.name = v;
    },

    initializeIdpAssessment() {
      self.idpAssessment = IDPSubitemAssessment.create({});
    },

    computeSubChoiceScore() {
      if (self.choiceInputType === config.subitemChoiceTypes.RADIO) {
        debugger;
        const choices = self.subitemScoreChoices.map((choice) => {
          const selected = choice.subitemScoreSubChoices.filter(
            (subChoice) => subChoice.selected
          );

          return selected.length > 0 ? selected[0] : null;
        });

        if (choices.filter((c) => c).length !== choices.length) {
          return false;
        }

        const score = choices.reduce((acc, c) => acc + (c?.score ?? 0), 0);
        self.score = score / choices.length;

        return true;
      }

      return false;
    },
  }));

export const RateItem = types
  .model({
    name: types.string,
    weight: types.number,
    maxScore: types.number,
    isKraSpecific: types.boolean,
    subitems: types.array(RateSubitem),
  })
  .actions((self) => ({
    setName(v: string) {
      self.name = v;
    },
  }))
  .views((self) => ({
    get scoreWeight() {
      const score = self.subitems
        .map((subitem) => subitem.score)
        .reduce((acc, c) => acc + c, 0);
      return (score / self.subitems.length) * (self.weight / 100);
    },
  }));

export const RateGroup = types
  .model({
    name: types.string,
    weight: types.number,
    type: types.number,
    itemColumnName: types.string,
    subitemColumnName: types.string,
    totalScore: types.optional(types.number, 0),
    items: types.array(RateItem),
  })
  .actions((self) => ({
    computeTotalScore() {
      if (self.type === config.formGroupTypes.BOB) {
        return self.totalScore;
      }
      const score = self.items
        .map((item) => item.scoreWeight)
        .reduce((acc, c) => acc + c, 0);

      const totalScore = score;

      self.totalScore = totalScore;
      return totalScore;
    },

    getRateDescription(): string {
      if (self.totalScore >= 4.5) {
        return "SIGNIFICANTLY ABOVE TARGET";
      }
      if (self.totalScore >= 4 && self.totalScore < 4.5) {
        return "ABOVE TARGET";
      }
      if (self.totalScore >= 3.5 && self.totalScore < 4) {
        return "ON TARGET";
      }
      return "BELOW TARGET";
    },

    setTotalScore(score: number) {
      if (self.type === config.formGroupTypes.BOB) {
        self.totalScore = score;
      }
    },
  }))
  .views((self) => ({
    get score() {
      if (self.type === config.formGroupTypes.BOB) {
        return self.totalScore;
      }

      const score = self.items
        .map((item) => item.scoreWeight)
        .reduce((acc, c) => acc + c, 0);

      return score;
    },
    get scoreWeight() {
      let score = 0;
      if (self.type === config.formGroupTypes.BOB) {
        score = self.totalScore;
      } else {
        score = self.items
          .map((item) => item.scoreWeight)
          .reduce((acc, c) => acc + c, 0);
      }

      return score * (self.weight / 100);
    },
  }));

export const Rate = types
  .model({
    id: types.maybe(types.number),
    uuid: types.maybe(types.string),
    year: types.optional(types.string, "2024"),
    period: types.optional(types.string, "2"),
    name: types.optional(types.string, ""),
    type: types.optional(types.number, 1),
    bobVersion: types.optional(types.string, "1"),
    ratee: types.optional(types.number, -1),
    rateeUser: types.maybe(User),
    rater: types.maybe(User),
    approver: types.maybe(User),
    actualApprover: types.maybe(User),
    totalScore: types.optional(types.number, 0),
    formTemplate: types.optional(types.number, -1),
    status: types.optional(types.number, 1),
    isSelfRate: types.optional(types.boolean, false),
    groups: types.array(RateGroup),
    idpDevelopmentPlan: types.optional(types.array(IDPDevelopmentPlan), [
      {},
      {},
      {},
    ]),
    pipImprovementPlan: types.optional(types.array(PIPImprovementPlan), [
      {},
      {},
      {},
    ]),
  })
  .actions((self) => ({
    setName(v: string) {
      self.name = v;
    },
    setType(v: number) {
      self.type = v;
    },
    setYear(v: string) {
      self.year = v;
    },
    setPeriod(v: string) {
      self.period = v;
    },
    setFormTemplate(v: number) {
      self.formTemplate = v;
    },
    setRatee(v: number) {
      self.ratee = v;
    },
    setGroups(groups: RateGroupType[]) {
      self.groups = cast(groups);
    },
    computeTotalScores() {
      const score = self.groups
        .map((group) => group.computeTotalScore() * (group.weight / 100))
        .reduce((acc, c) => acc + c, 0);
      self.totalScore = score;
    },

    setIDPDevelopmentPlan(count: number) {
      for (let i = 0; i < count; i++) {
        self.idpDevelopmentPlan.push({});
      }
    },

    initializePIPImprovementPlan() {
      const count = 3 - self.pipImprovementPlan.length;
      for (let i = 0; i < count; i++) {
        self.pipImprovementPlan.push({});
      }

      // foreach improvement plan make sure data are filled
      self.pipImprovementPlan.map((plan) => {
        const c = 3 - plan.interventions.length;
        for (let i = 0; i < c; i++) {
          plan.interventions.push({});
        }

        const eCount = 3 - plan.monthlyEvaluations.length;
        const eIndex = plan.monthlyEvaluations.length;
        for (let i = 0; i < eCount; i++) {
          plan.monthlyEvaluations.push({ monthNumber: eIndex + i + 1 });
        }
      });
    },
  }))
  .views((self) => {
    const overallScore = () => {
      const score = self.groups
        .map((group) => group.scoreWeight)
        .reduce((acc, c) => acc + c, 0);

      return score;
    };
    return {
      get overallScore() {
        return overallScore();
      },

      get rateDescription() {
        const score = overallScore();
        if (score >= 4.5) {
          return "SIGNIFICANTLY ABOVE TARGET";
        }
        if (score >= 4 && self.totalScore < 4.5) {
          return "ABOVE TARGET";
        }
        if (score >= 3.5 && self.totalScore < 4) {
          return "ON TARGET";
        }
        return "BELOW TARGET";
      },
    };
  });
