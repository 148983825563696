import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { get } from "lodash";
import { Controller, useFormContext, useFieldArray } from "react-hook-form";
import accounting from "accounting";
import classnames from "classnames";
import PropTypes from "prop-types";
import Select2 from "react-select2-wrapper";
import { cast } from "mobx-state-tree";
import { toast } from "react-toastify";
import { useMst } from "store/RootStore";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  Collapse,
  Button,
  Table,
  Modal,
} from "reactstrap";

import {
  RateGroupType,
  RateSubitemType,
  BobScores,
  UserType,
  RateItemType,
} from "../../../../../types";
import config from "../../../../../config";
import kra from "../../../../../kra";
import kra2023p2 from "../../../../../kra-2023-p2";

interface Props {
  index: number;
  errors: any;
  group: RateGroupType;
  bobScore?: BobScores | null;
  ratee?: UserType | null;
  year: string | null;
}

const CreateRateGroup: React.FC<Props> = (props) => {
  const { createRate } = useMst();
  const { group, errors, index, bobScore, year } = props;

  const [collapseOpen, setCollapseOpen] = useState(true);
  const [selectedSubitemModalKey, setSelectedSubitemModalKey] =
    useState<string>("");

  const handleMatrixRadioChanges = (subitem: RateSubitemType) => {
    if (!subitem.computeSubChoiceScore()) {
      toast.error("Please select at least one in each row");
    } else {
      setSelectedSubitemModalKey("");
    }
  };

  let kraChoices: { name: string; description: string }[] = [];
  if (props.ratee && props.ratee.profile && props.ratee.profile.positionCode) {
    if (year == '2023' || year == '2024')
    {
      kraChoices = kra2023p2[props.ratee.profile.positionCode.toLowerCase()] ?? [];
    }
    else
    {
      kraChoices = kra[props.ratee.profile.positionCode.toLowerCase()] ?? [];
    }
  }
  console.log("KRA", kraChoices);

  const handleKraChoiceChange = (index: number, item: RateItemType) => {
    const choice = kraChoices[index];
    if (choice) {
      item.setName(choice.name);
      item.subitems[0].setName(choice.description);
    } else {
      item.setName("");
      item.subitems[0].setName("");
    }
  };

  console.log(bobScore);

  return (
    <div className="accordion">
      <Row key={group.name}>
        <Col l="12">
          <Card>
            <CardHeader
              role="tab"
              onClick={() => setCollapseOpen(!collapseOpen)}
            >
              <div className="d-flex justify-content-between">
                <h5 className="h3 mb-0 d-flex ">
                  <span className="mr-2">{group.name}</span>
                  <span className="font-weight-bold">{group.weight}%</span>
                </h5>
                {bobScore && group.type === config.formGroupTypes.BOB ? (
                  <div className="mr-5">
                    <b>{bobScore.total}</b> ({bobScore.rating})
                  </div>
                ) : null}
              </div>
            </CardHeader>
            <Collapse role="tabpanel" isOpen={collapseOpen}>
              <CardBody>
                <FormGroup>
                  <Table
                    className="align-items-center"
                    style={{ tableLayout: "fixed" }}
                    responsive
                  >
                    <thead className="thead-light">
                      <tr>
                        <th style={{ width: 300 }}>Description</th>
                        <th style={{ width: 100 }}>Weight</th>
                        <th>Items</th>
                        <th>Score</th>
                        <th>Remarks</th>
                      </tr>
                    </thead>
                    <tbody>
                      {group.items.map((item, itemIndex) => {
                        const rowHeight =
                          item.subitems.length > 1
                            ? item.subitems.length * 100
                            : 220;
                        return (
                          <tr key={itemIndex}>
                            <td>
                              {item.isKraSpecific ? (
                                <select
                                  className="form-control"
                                  onChange={(e) =>
                                    handleKraChoiceChange(
                                      parseInt(e.target.value),
                                      item
                                    )
                                  }
                                >
                                  <option value={-1}>Please select KRA</option>
                                  {kraChoices &&
                                    kraChoices.map((c, i) => (
                                      <option key={i} value={i}>
                                        {c.name}
                                      </option>
                                    ))}
                                </select>
                              ) : (
                                item.name
                              )}
                              {get(
                                errors,
                                `groups[${index}].items[${itemIndex}].name`
                              ) ? (
                                <span className="text-danger mt-3 d-block">
                                  This is required
                                </span>
                              ) : null}
                            </td>
                            <td>{item.weight}%</td>
                            <td style={{ height: `${rowHeight}px` }}>
                              <div
                                className="d-flex flex-column h-100 justify-content-around word-wrap"
                                style={{ maxWidth: 500 }}
                              >
                                {item.subitems.map((subitem, subitemIndex) => (
                                  <div key={subitemIndex}>
                                    {subitem.editable ? (
                                      <textarea
                                        className="form-control"
                                        rows={5}
                                        value={subitem.name}
                                        onChange={(e) =>
                                          subitem.setName(e.target.value)
                                        }
                                      />
                                    ) : (
                                      subitem.name
                                    )}
                                    {get(
                                      errors,
                                      `groups[${index}].items[${itemIndex}].subitems[${subitemIndex}].name`
                                    ) ? (
                                      <span className="text-danger mt-3 d-block">
                                        This is required
                                      </span>
                                    ) : null}
                                  </div>
                                ))}
                              </div>
                            </td>
                            <td style={{ height: `${rowHeight}px` }}>
                              <div className="d-flex flex-column h-100 justify-content-around">
                                {item.subitems.map((subitem, subitemIndex) => {
                                  if (
                                    subitem.choiceInputType ===
                                    config.subitemChoiceTypes.DROPDOWN
                                  ) {
                                    return (
                                      <div key={subitemIndex} className="mb-2">
                                        {group.type ===
                                        config.formGroupTypes.BOB ? (
                                          <input
                                            type="text"
                                            className="form-control"
                                            disabled
                                            value={subitem.score}
                                          />
                                        ) : (
                                          <select
                                            style={{ width: 180 }}
                                            value={subitem.score}
                                            disabled={
                                              group.type ===
                                              config.formGroupTypes.BOB
                                            }
                                            className={classnames(
                                              "form-control",
                                              {
                                                "is-invalid": !!get(
                                                  errors,
                                                  `groups[${index}].items[${itemIndex}].subitems[${subitemIndex}].score`
                                                ),
                                              }
                                            )}
                                            onChange={(e) => {
                                              const value = parseFloat(
                                                e.target.value
                                              );
                                              createRate.groups[index].items[
                                                itemIndex
                                              ].subitems[subitemIndex].setScore(
                                                value
                                              );
                                            }}
                                            placeholder="Select"
                                          >
                                            <option>Select Score</option>
                                            {group.type ==
                                            config.formGroupTypes.BOB ? (
                                              <option value={0}>0</option>
                                            ) : null}
                                            {subitem.subitemScoreChoices.map(
                                              (choice) => (
                                                <option
                                                  key={choice.name}
                                                  value={choice.score}
                                                >
                                                  {choice.name}
                                                </option>
                                              )
                                            )}
                                          </select>
                                        )}
                                        {get(
                                          errors,
                                          `groups[${index}].items[${itemIndex}].subitems[${subitemIndex}].score`
                                        ) ? (
                                          <span className="text-danger mt-3 d-block">
                                            Score is required
                                          </span>
                                        ) : null}
                                      </div>
                                    );
                                  } else {
                                    return (
                                      <div key={subitemIndex} className="mb-2">
                                        <div className="d-flex flex-column">
                                          <div className="d-flex justify-content-center align-items-center">
                                            <span className="mr-3 font-weight-bold">
                                              {accounting.formatNumber(
                                                subitem.score,
                                                2
                                              )}
                                            </span>
                                            <Button
                                              className="btn-sm"
                                              onClick={() =>
                                                setSelectedSubitemModalKey(
                                                  `${index}-${itemIndex}-${subitemIndex}`
                                                )
                                              }
                                            >
                                              Set Score
                                            </Button>
                                          </div>
                                          {get(
                                            errors,
                                            `groups[${index}].items[${itemIndex}].subitems[${subitemIndex}].score`
                                          ) ? (
                                            <span className="text-danger mt-3 d-block">
                                              Score is required
                                            </span>
                                          ) : null}
                                        </div>

                                        <Modal
                                          style={{
                                            maxWidth: 850,
                                            minHeight: 500,
                                          }}
                                          className="modal-dialog-centered"
                                          isOpen={
                                            `${index}-${itemIndex}-${subitemIndex}` ===
                                            selectedSubitemModalKey
                                          }
                                        >
                                          <div className="modal-header">
                                            <h5
                                              className="modal-title"
                                              id="exampleModalLabel"
                                            >
                                              {item.name} ─ {subitem.name}
                                            </h5>
                                            <button
                                              aria-label="Close"
                                              className="close"
                                              data-dismiss="modal"
                                              type="button"
                                              onClick={() =>
                                                setSelectedSubitemModalKey("")
                                              }
                                            >
                                              <span aria-hidden={true}>×</span>
                                            </button>
                                          </div>

                                          <div className="modal-body">
                                            <Table>
                                              <thead>
                                                <tr>
                                                  <th>
                                                    Expert <br />
                                                    (Far Exceed Expectations)
                                                  </th>
                                                  <th>
                                                    {" "}
                                                    Competent <br />
                                                    (Successfully Met
                                                    Expectations)
                                                  </th>
                                                  <th>
                                                    {" "}
                                                    Intermediate <br />
                                                    (Met Some Expectations)
                                                  </th>
                                                  <th>
                                                    Green & Growing <br />
                                                    (Met Few Expectations)
                                                  </th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {subitem.subitemScoreChoices.map(
                                                  (
                                                    choice,
                                                    subitemChoiceIndex
                                                  ) => {
                                                    return (
                                                      <tr
                                                        key={subitemChoiceIndex}
                                                      >
                                                        {choice.subitemScoreSubChoices.map(
                                                          (
                                                            subChoice,
                                                            subchoiceindex
                                                          ) => {
                                                            return (
                                                              <td
                                                                key={
                                                                  subchoiceindex
                                                                }
                                                              >
                                                                <label
                                                                  style={{
                                                                    maxWidth: 500,
                                                                  }}
                                                                  className="word-wrap"
                                                                >
                                                                  <input
                                                                    type="radio"
                                                                    className="mr-2"
                                                                    name={`groups[${index}].items[${itemIndex}].subitems[${subitemIndex}].subitemChoices[${subitemChoiceIndex}].score`}
                                                                    onChange={(
                                                                      e: any
                                                                    ) => {
                                                                      // subChoice.setSelected(
                                                                      //   e.target
                                                                      //     .checked
                                                                      // );

                                                                      choice.setSubchoiceSelected(
                                                                        subchoiceindex
                                                                      );
                                                                      choice.setScore(
                                                                        parseFloat(
                                                                          e
                                                                            .target
                                                                            .value
                                                                        )
                                                                      );
                                                                    }}
                                                                    value={
                                                                      subChoice.score
                                                                    }
                                                                    checked={
                                                                      subChoice.selected
                                                                    }
                                                                  ></input>
                                                                  {
                                                                    subChoice.description
                                                                  }
                                                                </label>
                                                              </td>
                                                            );
                                                          }
                                                        )}
                                                      </tr>
                                                    );
                                                  }
                                                )}
                                              </tbody>
                                            </Table>
                                          </div>

                                          <div className="modal-footer">
                                            <Button
                                              className="btn-sm"
                                              color="primary"
                                              type="button"
                                              onClick={() =>
                                                handleMatrixRadioChanges(
                                                  subitem
                                                )
                                              }
                                            >
                                              Save changes
                                            </Button>
                                          </div>
                                        </Modal>
                                      </div>
                                    );
                                  }
                                })}
                              </div>
                            </td>
                            <td style={{ height: `${rowHeight}px` }}>
                              <div className="d-flex flex-column h-100 justify-content-around">
                                {item.subitems.map((subitem, subitemIndex) => (
                                  <div key={subitemIndex} className="mb-2">
                                    <textarea
                                      style={{ width: 250 }}
                                      className={classnames("form-control", {
                                        "is-invalid": !!get(
                                          errors,
                                          `groups[${index}].items[${itemIndex}].subitems[${subitemIndex}].remarks`
                                        ),
                                      })}
                                      value={subitem.remarks}
                                      onChange={(e: any) =>
                                        subitem.setRemarks(e.target.value)
                                      }
                                      placeholder="Remarks"
                                    ></textarea>
                                    {get(
                                      errors,
                                      `groups[${index}].items[${itemIndex}].subitems[${subitemIndex}].remarks`
                                    ) ? (
                                      <span className="text-danger mt-3 d-block">
                                        Remarks is required
                                      </span>
                                    ) : null}
                                  </div>
                                ))}
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </FormGroup>
              </CardBody>
            </Collapse>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

CreateRateGroup.propTypes = {
  errors: PropTypes.any.isRequired,
  group: PropTypes.any.isRequired,
  index: PropTypes.number.isRequired,
  bobScore: PropTypes.any,
  ratee: PropTypes.any,
};

export default observer(CreateRateGroup);
