const rgm: { name: string; description: string }[] = [
  {
    name: "Cash & Funds Control Program Effectiveness",
    description: `•	Ensures proper implementation of Cash and Funds Control programs
    •	Financial Audit: cash reconciles findings with the internal auditor
    •	Verifies that all deposits (correct amount) have been received by the bank on time`,
  },
  {
    name: "Competence of Management Trainee and RTMs",
    description: `•	Conducts training for Management Trainee and RTMs and must keep their competence level high`,
  },
  {
    name: "Conduct Meetings",
    description: `"•	Conducts store management team meeting and RTMs general meeting at least once a month"`,
  },
  {
    name: "Customer Complaints",
    description: `"•	Ensure immediate corrective and preventive actions on complaints are done
    •	Close customer complaints within 24 if applicable"`,
  },
  {
    name: "Disciplinary Actions",
    description: `"•	Ensures employee benefits program are administered properly"`,
  },
  {
    name: "Managing the Restaurant P&L",
    description: `"•	Hold controllable P&L line items within budget
    •	Reconciles monthly P&L
    •	Discuss P&L to the management team"`,
  },
  {
    name: "New Products and Procedures",
    description: `·         Proper implementation of new products and procedures`,
  },
  {
    name: "Overall Restaurant Management|Supervision",
    description: `"•	Ensures that Assistant Restaurant Managers are the ones doing their respective KRA
    •	Gives the manager constant feedback on their delivery of KRA 
    •	Check if the RTMs is doing exactly what they have been trained to do
    •	Do coaching and counseling for managers and RTMs needed"`,
  },
  {
    name: "Overall Store Management Development Program",
    description: `"•	Ensures an adequate number of staff for Management Team, RTMs and other positions needed to effectively and efficiently operate the restaurant"`,
  },
  {
    name: "Planning",
    description: `·         Prepares Systematic Managerial Analysis (SMA) and SWOT Analysis regularly`,
  },
  {
    name: "Profit & Cost Effectivity and Efficiency",
    description: `"•	Sets restaurant budget
    •	Creates programs to reduce cost
    •	Effectively manage productivity and efficiency in-store operation"`,
  },
  {
    name: "Reports",
    description: `"•	Accomplishes monthly store operating reports such as weekly, mid-month|month end inventory and usages, petty cash, paid out, deposits, and P&L line item contributors
    •	Review and check the accuracy of reports and paper works prepared by assistants"`,
  },
  {
    name: "Restaurant Operations Compliance Check (ROCC) Programs",
    description: `"•	Creates long term store objectives, action plans, and programs for ROCC and improvement and consistency"`,
  },
  {
    name: "Restaurant Safety and Security Plan Effectiveness",
    description: `"•	Ensures that safety and security programs are in place in the restaurant
    •	Proper dissemination and implementation"`,
  },
  {
    name: "RTMs Recruitment",
    description: `"•	Hiring and selection of RTMs & SMs
    •	Ensures quality of hired RTMs is according to set standards"`,
  },
  {
    name: "Sales Building Awareness",
    description: `"•	Defines the restaurant’s trade area, major competitors, and traffic generators and use the same information to maximize sales
    •	Pro-active in sales building awareness (customer preferences, suggestive selling, trade area sensitivity) and communicate the same with the team
    •	Look for potential sales|marketing opportunities within the retail trade area"`,
  },
  {
    name: "Sales Target",
    description: `·         Setting and achievement of Sales Forecasts/targets`,
  },
  {
    name: "Scheduling",
    description: `"•	Prepares management team schedule"`,
  },
  {
    name: "Voice Of Champions|KFC VOICE",
    description: `·         Measures team’s behavioral contribution to restaurant success`,
  },
  {
    name: "Labour Management (Controllable: Direct Labor)",
    description: `"•	Manpower forecasting
    •	RTMs schedule
    •	Proper distribution and utilization of man-hours
    •	Attainment of labor cost target, TPMH and % improvement versus Last Year "`,
  },
  {
    name: "Local Store Marketing|Catering and Bulk Orders",
    description: `"•	Design and implements all LSM programs effectively
    •	Looks for potential sales opportunity within the retail trade area through outside selling"`,
  },
  {
    name: "Morale of RTM",
    description: `"•	Prepares and evaluated programs the will boost RTM morale
    •	Ensures employees benefits and premiums are administered properly and promptly "`,
  },
  { name: "Appraisals and Promotions", description: `` },
  { name: "Cash & Funds Control Program Effectiveness", description: `` },
  { name: "Competence of Management Trainee and RTMs", description: `` },
  {
    name:
      "Compliance to Government as well as Mall Requirements and Regulations",
    description: ``,
  },
  { name: "Conduct Meetings", description: `` },
  { name: "Customer Complaints", description: `` },
  { name: "Disciplinary Actions", description: `` },
  { name: "Employee Benefits", description: `` },
  { name: "Managing the Restaurant P&L", description: `` },
  { name: "New Products and Procedures", description: `` },
  { name: "Overall Restaurant Management|Supervision", description: `` },
  { name: "Overall Store Management Development Program", description: `` },
  { name: "Planning", description: `` },
  { name: "Profit & Cost Effectivity and Efficiency", description: `` },
  { name: "Reports", description: `` },
  {
    name: "Restaurant Operations Compliance Check (ROCC) Programs",
    description: ``,
  },
  {
    name: "Restaurant Safety and Security Plan Effectiveness",
    description: ``,
  },
  { name: "RTMs Recruitment", description: `` },
  { name: "Sales Building Awareness", description: `` },
  { name: "Sales Program Effectiveness", description: `` },
  { name: "Sales Target", description: `` },
  { name: "Scheduling", description: `` },
  { name: "Voice Of Champions|KFC VOICE", description: `` },
];

const jam: { name: string; description: string }[] = [
  {
    name: "Colonel’s Party",
    description: `•	Responsible for the Colonel’s Party operations in the restaurant
    •	Ensures logistics need for party reservations are properly coordinated
    •	Training and development of Restaurant’s party host and mascot talent
    •	Attainment of Colonel’s Party Sales targets and increase sales and booking over last year
    •	Marketing and promotion of Colonel’s party in the restaurant’s trade area`,
  },
  {
    name: "Competence of Team Members",
    description: `•	Training of newly hired RTM (trainee)
    •	Knowledgeable on the Team Member Training and All-Star Certification`,
  },
  {
    name: "Controllables:   Food Cost, Operating Supplies, & Office Supplies",
    description: `•	Design and implement control procedures in the restaurant 
    •	Attainment of food, packaging, restaurant and office supplies cost target`,
  },
  {
    name:
      "Controllables: Utilities, Cleaning Supplies, Office Supplies, Smallwares and Breakages and Losses",
    description: `•	Ensure strict compliance of the control measures
    •	Attainment of Electric, Water, cleaning supplies, Smallwares, and Breakages and Losses target`,
  },
  {
    name: "Development of Team",
    description: `•	Assist the RGM in the development and training of the other assistants or managers`,
  },
  {
    name: "Equipment Maintenance",
    description: `•	Ensures cleanliness and good working condition of the equipment
    •	Performs basic equipment calibration and troubleshooting tasks`,
  },
  {
    name: "Equipment Maintenance",
    description: `•	Can do minor troubleshooting 
    •	Ensure cleanliness and maintenance of equipment`,
  },
  {
    name: "Equipment Maintenance and Calibration Plan Effectiveness",
    description: `•	Equipment and preventive maintenance program
    •	Set repairs and maintenance schedule
    •	Coordinates with the concerned department`,
  },
  {
    name: "Financial Audit: Product Inventory",
    description: `•	Monitors product movement and efficiencies (Theoretical versus Actual)
    •	Design and execute a plan on improving gap efficiencies`,
  },
  {
    name: "Forecasting/Ordering",
    description: `•	Effective implementation of Clearview function for forecasting
    •	Mini-max projection level
    •	Plan restaurant build-to for ordering (food, paper and other supplies) and adjustments`,
  },
  {
    name: "Gold Standard (Branded Service)",
    description: `Consistent implementation of Gold Standard service programs in the restaurant such as Colonelling and Gold Standard Activations`,
  },
  {
    name: "Good Housekeeping",
    description: `Responsible for the orderliness and cleanliness including completeness of daily nightly reports, customer complaint logbooks, daily and nightly envelopes or equivalent`,
  },
  {
    name: "Home Delivery Service System Effectiveness",
    description: `•	Responsible for HD Service operations in the restaurant
    •	Ensures that standard HD Service procedures are implemented
    •	Monitor maintenance of HD tools such as Just Eat Monitor and Skip the Dishes tablet
    •	Training and development of restaurant delivery team
    •	Attainment of Delivery Sales Target and bottom line
    •	Establish the restaurant’s home delivery trade area
    •	Marketing and promotion of HD in the trade area`,
  },
  {
    name: "Inventory",
    description: `Accountable for the inventory of Colonel’s Party premium items and party favors`,
  },
  {
    name:
      "Knowledge and Understanding of Restaurant Operations – Solo Shift Duties",
    description: `•	Knows and understands overall restaurant operations, standards, and systems
    •	Manage opening and closing shifts alone 
    •	Control labor hours, cash handling, wastages, and yields on shifts
    •	Follow up procedures that support marketing promotion during the shift
    •	Responsible for the safe contents during the shift operation (safe float)
    •	Uses procedures checklists (OPS Diary) in verifying  RTMS performance
    •	Supervises deliveries of raw products, packaging and operating supplies
    •	Maintains critical standards for holding times, service speeds, quality of raw and finished goods, cleanliness, and sanitation
    •	Prepares people, equipment, and product for the shift
    •	Completes daily paper works and inputs to the Back Office System (Clearview)
    •	Performs basic equipment check`,
  },
  {
    name: "Knowledgeable on Restaurant P&L Statement",
    description: `•	Assists the RGM to project and attain monthly P&L budget accurately
    •	Ability to read, understand, and analyze P&L
    •	Can prepare an action plan to improve next month’s P&L`,
  },
  {
    name: "Knowledgeable on RTMS functions",
    description: `· Can perform all crew functions including daily maintenance tasks especially equipment start-up
    · Can train people to do RTMS functions`,
  },
  {
    name: "Labour Management (Controllable: Direct Labor)",
    description: `•	Manpower forecasting
    •	RTMs schedule
    •	Proper distribution and utilization of man-hours
    •	Attainment of labor cost target, TPMH and % improvement versus Last Year `,
  },
  {
    name: "Local Store Marketing|Catering and Bulk Orders",
    description: `•	Design and implements all LSM programs effectively
    •	Looks for potential sales opportunity within the retail trade area through outside selling`,
  },
  {
    name: "Maintains Store Operations in the Absence of the RGM",
    description: `•	Recruitment and Orientation of RTMs
    •	Manages Team Member training program including Learning Zone Management
    •	Satisfactorily conducts one-on-one with the RTMs
    •	Discipline RTMs in accordance with HR Policies
    •	Plans|Scheudules|Completes RTMs performance evaluation (Observation Checklist|OCL|MOT)
    •	Submits comments for the PMS of all management team members
    •	Identifies RTMs for promotion
    •	Assist Management recruitment through ore-hiring activities
    •	Conduct a management team meeting to review the previous month’s performance and P&L line items
    •	Implementation of Management Team’s Development Plan
    •	Trains the Management Trainee on basic equipment calibration and maintenance
    •	Trains crew trainers 
    •	Assist RGM on the roll-out of new products and procedures
    •	Implementation of market-wide promotions
    •	Review labor hours regularly
    •	Holds controllable P&L line items within the budget
    •	Reconciles monthly P&L with Area Coach
    •	Accomplish monthly store operating reports
    •	Verifies all the deposits (correct amount and on time) have been received by the bank
    •	Reconcile reports with Accounting
    •	Assist in the projection of annual sales and profit plan
    •	Works with the RGM in achieving long-term objectives and action plan for  GES, ROCC, Sales, Profit, and People Development
    •	Accomplish other tasks assigned to him|her time to time`,
  },
  {
    name: "Morale of RTM",
    description: `•	Prepares and evaluated programs the will boost RTM morale
    •	Ensures employees benefits and premiums are administered properly and promptly `,
  },
  {
    name: "One-on-One Training ",
    description: `•	Conduct one-on-one training with RTMs`,
  },
  {
    name: "People handling",
    description: `•	Practices basic Human relations skills
    •	Knows and enforces all appropriate personnel policies and labor laws`,
  },
  {
    name: "Restaurant Administration System Effectiveness",
    description: `•	Ensures completeness, accuracy, and timeliness of records, files, and logbooks`,
  },
  {
    name: "RTMs Meeting",
    description: `•	Conduct Restaurant’s General Meeting with RGM or in the absence of the RGM`,
  },
  {
    name: "Solo Shift Duties",
    description: `•	Successfully handles various shift alone
    •	Has excellent operational knowledge of stations, quality references, and effective floor control
    •	Consistent in handling principal accountabilities of a duty manager`,
  },
  {
    name: "Station Meetings",
    description: `·         Conducts Station Meetings`,
  },
  {
    name: "Timely Submission of reports",
    description: `•	Ensures that all daily, weekly, mid-month and month-end reports are submitted accurately and on time
    •	Follow up and reminds co-managers of the deadline`,
  },
  {
    name: "Scheduling",
    description: `·         Prepares management team schedule`,
  },
  {
    name: "Voice Of Champions|KFC VOICE",
    description: `·         Measures team’s behavioral contribution to restaurant success`,
  },
  {
    name: "Labour Management (Controllable: Direct Labor)",
    description: `•	Manpower forecasting
    •	RTMs schedule
    •	Proper distribution and utilization of man-hours
    •	Attainment of labor cost target, TPMH and % improvement versus Last Year `,
  },
  {
    name: "Local Store Marketing|Catering and Bulk Orders",
    description: `•	Design and implements all LSM programs effectively
    •	Looks for potential sales opportunity within the retail trade area through outside selling`,
  },
];

const agm: { name: string; description: string }[] = [
  {
    name: "Controllables:   Food Cost, Operating Supplies, & Office Supplies",
    description: `"•	Design and implement control procedures in the restaurant 
    •	Attainment of food, packaging, restaurant and office supplies cost target"`,
  },
  {
    name: "Development of Team",
    description: `"•	Assist the RGM in the development and training of the other assistants or managers"`,
  },
  {
    name: "Equipment Maintenance and Calibration Plan Effectiveness",
    description: `"•	Equipment and preventive maintenance program
    •	Set repairs and maintenance schedule
    •	Coordinates with the concerned department"`,
  },
  {
    name: "Financial Audit: Product Inventory",
    description: `"•	Equipment and preventive maintenance program
    •	Set repairs and maintenance schedule
    •	Coordinates with the concerned department"`,
  },
  {
    name: "Forecasting",
    description: `"•	Effective implementation of Clearview function for forecasting
    •	Mini-max projection level
    •	Plan restaurant build-to for ordering (food, paper and other supplies) and adjustments"`,
  },
  {
    name: "Knowledgeable on Restaurant P&L Statement",
    description: `"•	Assists the RGM to project and attain monthly P&L budget accurately
    •	Ability to read, understand, and analyze P&L
    •	Can prepare an action plan to improve next month’s P&L"`,
  },
  {
    name: "Labour Management (Controllable: Direct Labor)",
    description: `"•	Manpower forecasting
    •	RTMs schedule
    •	Proper distribution and utilization of man-hours
    •	Attainment of labor cost target, TPMH and % improvement versus Last Year "`,
  },
  {
    name: "Local Store Marketing|Catering and Bulk Orders",
    description: `"•	Design and implements all LSM programs effectively
    •	Looks for potential sales opportunity within the retail trade area through outside selling"`,
  },
  {
    name: "Maintains Store Operations in the Absence of the RGM",
    description: `"•	Recruitment and Orientation of RTMs
    •	Manages Team Member training program including Learning Zone Management
    •	Satisfactorily conducts one-on-one with the RTMs
    •	Discipline RTMs in accordance with HR Policies
    •	Plans|Scheudules|Completes RTMs performance evaluation (Observation Checklist|OCL|MOT)
    •	Submits comments for the PMS of all management team members
    •	Identifies RTMs for promotion
    •	Assist Management recruitment through ore-hiring activities
    •	Conduct a management team meeting to review the previous month’s performance and P&L line items
    •	Implementation of Management Team’s Development Plan
    •	Trains the Management Trainee on basic equipment calibration and maintenance
    •	Trains crew trainers 
    •	Assist RGM on the roll-out of new products and procedures
    •	Implementation of market-wide promotions
    •	Review labor hours regularly
    •	Holds controllable P&L line items within the budget
    •	Reconciles monthly P&L with Area Coach
    •	Accomplish monthly store operating reports
    •	Verifies all the deposits (correct amount and on time) have been received by the bank
    •	Reconcile reports with Accounting
    •	Assist in the projection of annual sales and profit plan
    •	Works with the RGM in achieving long-term objectives and action plan for  GES, ROCC, Sales, Profit, and People Development
    •	Accomplish other tasks assigned to him|her time to time"`,
  },
  {
    name: "Morale of RTM",
    description: `"•	Prepares and evaluated programs the will boost RTM morale
    •	Ensures employees benefits and premiums are administered properly and promptly "`,
  },
  {
    name: "One-on-One Training ",
    description: `"•	Conduct one-on-one training with RTMs"`,
  },
  {
    name: "RTMs Meeting",
    description: `"•	Conduct Restaurant’s General Meeting with RGM or in the absence of the RGM"`,
  },
];
const sm: { name: string; description: string }[] = [...agm, ...jam];

const kra: { [key: string]: { name: string; description: string }[] } = {
  rgm,
  jam,
  agm: [...jam],
  sm: [...jam],
};

export default kra;
