import React, { useState, useEffect } from "react";
import accounting from "accounting";
import Skeleton from "react-loading-skeleton";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardHeader,
  Table,
  UncontrolledPopover,
  PopoverBody,
  Spinner,
} from "reactstrap";
import { useMst } from "store/RootStore";
import { observer } from "mobx-react";
import { useHistory, useParams } from "react-router";
import { RateType } from "types";
import { toast } from "react-toastify";
import { Rate } from "store/models/Rate";
import { applySnapshot, getSnapshot } from "mobx-state-tree";
import config from "config";

const ManageIDP: React.FC = observer(() => {
  const store = useMst();
  const history = useHistory();
  const { id } = useParams();
  const [pageLoading, setPageLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const rate = store.showRate;

  useEffect(() => {
    setPageLoading(true);
    store.userAccount
      .getRate(id)
      .then((res) => res.data.data)
      .then((data) => {
        applySnapshot(store.showRate, data);

        store.showRate.groups[0].items.map((item) => {
          item.subitems.map((subitem) => {
            if (!subitem.idpAssessment) {
              subitem.initializeIdpAssessment();
            }
          });
        });
        if (store.showRate.idpDevelopmentPlan.length < 3) {
          const c = 3 - store.showRate.idpDevelopmentPlan.length;
          store.showRate.setIDPDevelopmentPlan(c);
        }
        store.showRate.idpDevelopmentPlan.map((plan) => {
          const remaining = 3 - plan.interventions.length;
          if (remaining > 0) {
            plan.setInterventions(remaining);
          }
        });
      })
      .catch((e) => console.log(e))
      .then(() => setPageLoading(false));
  }, [id]);

  const group = store.showRate ? store.showRate.groups[0] : null;

  const handleSave = async () => {
    try {
      setIsSubmitting(true);
      await store.userAccount.saveIDP(store.showRate.uuid, store.showRate);
      toast.success("Successfully saved IDP");
      history.push(`/admin/rate_employee/${store.showRate.uuid}/details`);
      setIsSubmitting(false);
    } catch (e) {
      setIsSubmitting(false);
      console.log(e);
      toast.error("Failed to save IDP, please try again later");
    }
  };

  return (
    <>
      <div className="header bg-default pb-6">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col lg="6" xs="7">
                <h6 className="h2 text-white d-inline-block mb-0">
                  Manage IDP
                </h6>
              </Col>
              <Col className="text-right" lg="6" xs="5"></Col>
            </Row>
          </div>
        </Container>
      </div>

      <Container className="mt--6" fluid>
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <h5 className="h3 mb-0">
                  {rate?.name} - {rate?.year}
                </h5>
              </CardHeader>
              {pageLoading ? (
                <CardBody>
                  <Skeleton count={5} />
                </CardBody>
              ) : (
                <CardBody>
                  <Table>
                    <tbody>
                      <tr>
                        <td style={{ width: 140 }}>
                          <b>Ratee</b>
                        </td>
                        <td>
                          {rate?.rateeUser?.firstName}{" "}
                          {rate?.rateeUser?.lastName}
                        </td>
                        <td style={{ width: 140 }}>
                          <b>Ratee Dept</b>
                        </td>
                        <td>{rate?.rateeUser?.profile?.department}</td>
                      </tr>
                      <tr>
                        <td style={{ width: 140 }}>
                          <b>Ratee Position</b>
                        </td>
                        <td>{rate?.rateeUser?.profile?.position}</td>
                        <td style={{ width: 140 }}>
                          <b>Ratee Employee No</b>
                        </td>
                        <td>{rate?.rateeUser?.profile?.employeeNumber}</td>
                      </tr>
                      <tr>
                        <td>
                          <b>Ratee Division</b>
                        </td>
                        <td>{rate?.rateeUser?.profile?.division}</td>
                        <td>
                          <b>Period</b>
                        </td>
                        <td>
                          {rate?.period == "1" ? "First" : "Second"} (
                          {rate?.year})
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                  <hr />
                  <Table>
                    <tbody>
                      <tr>
                        <td style={{ width: 140 }}>
                          <b>Rater</b>
                        </td>
                        <td>
                          {rate?.rater?.firstName} {rate?.rater?.lastName}
                        </td>
                        <td style={{ width: 140 }}>
                          <b>Rater Dept</b>
                        </td>
                        <td>{rate?.rater?.profile?.department}</td>
                      </tr>
                      <tr>
                        <td>
                          <b>Rater Position</b>
                        </td>
                        <td>{rate?.rater?.profile?.position}</td>
                        <td>
                          <b>Rater Employee No</b>
                        </td>
                        <td>{rate?.rater?.profile?.employeeNumber}</td>
                      </tr>
                      <tr>
                        <td>
                          <b>Rater Division</b>
                        </td>
                        <td>{rate?.rateeUser?.profile?.division}</td>
                        <td>
                          <b>Period</b>
                        </td>
                        <td>
                          {rate?.period == "1" ? "First" : "Second"} (
                          {rate?.year})
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </CardBody>
              )}
            </Card>

            {group ? (
              <Card key={group.name}>
                <CardHeader>
                  <h5 className="h3 mb-0">{group.name}</h5>
                </CardHeader>
                <CardBody>
                  <Table className="align-items-center" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th>Description</th>
                        <th>Weight</th>
                        <th>Items</th>
                        <th>Score</th>
                        <th>With Competency Gap</th>
                        <th>Remarks</th>
                        <th>Action Plan</th>
                      </tr>
                    </thead>
                    <tbody>
                      {group.items.map((item, itemIndex) => {
                        return (
                          <tr key={itemIndex}>
                            <td>{item.name}</td>
                            <td>{item.weight}%</td>
                            <td style={{ height: 1 }}>
                              <div
                                className="d-flex flex-column h-100 justify-content-around word-wrap"
                                style={{ maxWidth: 500 }}
                              >
                                {item.subitems.map((subitem, index) => (
                                  <div key={index}>{subitem.name}</div>
                                ))}
                              </div>
                            </td>
                            <td style={{ height: 1 }}>
                              <div className="d-flex flex-column h-100 justify-content-around">
                                {item.subitems.map((subitem, index) => (
                                  <div
                                    key={index}
                                    className="font-weight-bold mb-3"
                                  >
                                    {subitem.score}
                                  </div>
                                ))}
                              </div>
                            </td>
                            <td style={{ height: 1 }}>
                              <div className="d-flex flex-column h-100 justify-content-around">
                                {item.subitems.map((subitem, index) => (
                                  <div
                                    key={index}
                                    className="font-weight-bold mb-3"
                                  >
                                    <select
                                      className="form-control"
                                      value={
                                        subitem.idpAssessment?.competencyGap
                                          ? "1"
                                          : "0"
                                      }
                                      onChange={(e) =>
                                        subitem.idpAssessment?.setCompetencyGap(
                                          e.target.value === "1"
                                        )
                                      }
                                    >
                                      <option value="0">No</option>
                                      <option value="1">Yes</option>
                                    </select>
                                  </div>
                                ))}
                              </div>
                            </td>
                            <td style={{ height: 1, minWidth: 350 }}>
                              <div className="d-flex flex-column h-100 justify-content-around">
                                {item.subitems.map((subitem, index) => (
                                  <div
                                    key={index}
                                    className="font-weight-bold mb-3"
                                  >
                                    <textarea
                                      rows={3}
                                      className="form-control"
                                      value={subitem.idpAssessment?.remarks}
                                      onChange={(e) =>
                                        subitem.idpAssessment?.setRemarks(
                                          e.target.value
                                        )
                                      }
                                    />
                                  </div>
                                ))}
                              </div>
                            </td>
                            <td style={{ height: 1 }}>
                              <div className="d-flex flex-column h-100 justify-content-around">
                                {item.subitems.map((subitem, index) => (
                                  <div key={index}>
                                    {subitem.idpAssessment?.competencyGap ? (
                                      <span className="text-danger">
                                        Prioritize for development
                                      </span>
                                    ) : (
                                      <span> No need to address</span>
                                    )}
                                  </div>
                                ))}
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            ) : null}

            <Card>
              <CardHeader>
                <h5 className="h3 mb-0">Development Plan</h5>
              </CardHeader>
              <CardBody>
                <Table className="align-items-center" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th>Priority KRAs for Development</th>
                      <th>Select applicable interventions</th>
                      <th>Details</th>
                      <th>Completion Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {rate.idpDevelopmentPlan.map((plan, index) => (
                      <tr key={index}>
                        <td>
                          <select
                            value={plan.developmentPlanType ?? ""}
                            onChange={(e) => {
                              plan.setDevelopmentPlanType(e.target.value);
                            }}
                            className="form-control"
                          >
                            <option value="">None</option>
                            {Object.keys(config.developmentPlans).map((key) => (
                              <option key={key} value={key}>
                                {config.developmentPlans[key]}
                              </option>
                            ))}
                          </select>
                        </td>
                        <td style={{ height: 1 }}>
                          <div className="d-flex flex-column justify-content-around h-100">
                            {plan.interventions.map((intervention, i) => (
                              <select
                                value={intervention.interventionType ?? ""}
                                onChange={(e) =>
                                  intervention.setInterventionType(
                                    e.target.value
                                  )
                                }
                                key={i}
                                className="form-control mb-2"
                              >
                                <option value="">None</option>
                                {Object.keys(config.interventions).map(
                                  (key) => (
                                    <option key={key} value={key}>
                                      {config.interventions[key]}
                                    </option>
                                  )
                                )}
                              </select>
                            ))}
                          </div>
                        </td>
                        <td>
                          {plan.interventions.map((intervention, i) => (
                            <textarea
                              key={i}
                              rows={3}
                              value={intervention.remarks}
                              onChange={(e) =>
                                intervention.setRemarks(e.target.value)
                              }
                              className="form-control mb-2"
                            />
                          ))}
                        </td>
                        <td style={{ height: 1 }}>
                          <div className="d-flex flex-column justify-content-around h-100">
                            {plan.interventions.map((intervention, i) => (
                              <input
                                key={i}
                                type="text"
                                value={intervention.completionDate}
                                onChange={(e) =>
                                  intervention.setCompletionDate(e.target.value)
                                }
                                className="form-control mb-2"
                              />
                            ))}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <Button
                  disabled={isSubmitting}
                  onClick={() => handleSave()}
                  className="btn btn-default"
                >
                  {isSubmitting ? "Saving..." : "Save"}
                </Button>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
});

export default ManageIDP;
