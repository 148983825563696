import React, { useEffect } from "react";
import classnames from "classnames";
import { Route, Switch, Redirect, RouteComponentProps } from "react-router-dom";
import styled from "@emotion/styled";

import routes from "routes";
import { AppRoutes, AppRouteCollection, AppRouteView } from "types";

const Wrapper = styled.div`
  /* background: #881b31; */
  /* min-height: 100vh; */
`;

const Auth: React.FC<RouteComponentProps> = () => {
  useEffect(() => {
    document.body.classList.add("bg-default");
    // document.body.classList.remove("bg-default");
  }, []);

  const getRoutes = (routes: AppRoutes): any => {
    return routes.map((prop, key) => {
      const route = prop as AppRouteCollection;
      if (route.collapse) {
        return getRoutes(route.views);
      }

      const routeView = prop as AppRouteView;
      if (routeView.layout === "/auth") {
        return (
          <Route
            path={routeView.layout + routeView.path}
            component={routeView.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <>
      <Wrapper className="main-content">
        <Switch>
          {getRoutes(routes)}
          <Redirect from="*" to="/auth/login" />
        </Switch>
      </Wrapper>
    </>
  );
};

export default Auth;
