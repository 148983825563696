import { RateType, FormTemplateHeaderType } from "types";
import { cast } from "mobx-state-tree";

export const convertFormToRate = (form: FormTemplateHeaderType): RateType => {
  const data = {
    id: 0,
    uuid: "",
    name: form.name,
    type: form.type,
    bobVersion: form.bobVersion,
    ratee: 0,
    year: "2022",
    period: "1",
    formTemplate: 0,
    groups: form.groups.map((group) => {
      return {
        name: group.name,
        weight: parseFloat(group.weight.toString()),
        type: group.type,
        itemColumnName: group.itemColumnName,
        subitemColumnName: group.subitemColumnName,
        items: group.items.map((item) => ({
          name: item.name,
          weight: parseFloat(item.weight.toString()),
          maxScore: item.maxScore,
          isKraSpecific: item.isKraSpecific,
          subitems: item.subitems.map((subitem) => ({
            name: subitem.name,
            choiceInputType: subitem.choiceInputType,
            editable: subitem.editable,
            weight: parseFloat(subitem.weight.toString()),
            subitemScoreChoices: subitem.subitemScoreChoices.map((choice) => ({
              name: choice.name,
              subtitle: choice.subtitle,
              description: choice.description,
              score: parseFloat(choice.score.toString()),
              // selectedScore: 0,
              subitemScoreSubChoices: choice.subitemScoreSubChoices.map(
                (c) => ({
                  name: c.name,
                  subtitle: c.subtitle,
                  description: c.description,
                  score: parseFloat(c.score.toString()),
                  selected: false,
                })
              ),
            })),

            score: 0,
            remarks: "",
          })),
        })),
      };
    }),
  };

  return cast<RateType>(data);
};

export const getRateDescription = (totalScore: number): string => {
  if (totalScore >= 4.5) {
    return "SIGNIFICANTLY ABOVE TARGET";
  }
  if (totalScore >= 4 && totalScore < 4.5) {
    return "ABOVE TARGET";
  }
  if (totalScore >= 3.5 && totalScore < 4) {
    return "ON TARGET";
  }
  return "BELOW TARGET";
};
