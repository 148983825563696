import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components
import { Container, Row, Col } from "reactstrap";

interface Props {
  title: string;
  lead: string;
}

const AuthHeader: React.FC<Props> = (props) => {
  return (
    <>
      <div className="header bg-white py-7 py-lg-8 pt-lg-9">
        <Container>
          <div className="header-body text-center mb-5">
            <Row className="justify-content-center">
              <Col className="px-5" lg="6" md="8" xl="5">
                {props.title ? <h1 className="">{props.title}</h1> : null}
                {props.lead ? <p className="text-lead ">{props.lead}</p> : null}
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <div
        className="separator separator-bottom separator-skew zindex-100"
        style={{ bottom: 260 }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          version="1.1"
          viewBox="0 0 2560 100"
          x="0"
          y="0"
        >
          <polygon className="fill-default" points="2560 0 2560 100 0 100" />
        </svg>
      </div>
    </>
  );
};

AuthHeader.propTypes = {
  title: PropTypes.string.isRequired,
  lead: PropTypes.string.isRequired,
};

export default AuthHeader;
