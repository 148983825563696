import React from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// reactstrap components
// core components
import CardsHeader from "components/Headers/CardsHeader.js";

const Dashboard: React.FC = () => {
  return (
    <>
      <CardsHeader name="Default" parentName="Dashboards" />
    </>
  );
};

export default Dashboard;
