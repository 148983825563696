const subitemChoiceTypes: { [key: string]: number } = {
  RADIO: 1,
  DROPDOWN: 2,
};

const formHeaderTypes: { [key: string]: number } = {
  WITHOUT_IDP_PIP: 0,
  WITH_IDP_PIP: 1,
};

const rateStatusesLabels: { [key: number]: string } = {
  1: "Pending",
  5: "For Approval",
  7: "For discussion with ratee",
  10: "Rate acknowleged",
};

const developmentPlans: { [key: string]: string } = {
  turnover: "TURNOVER",
  rocc: "ROCC",
  core: "CORE",
  "kfc-voice": "KFC VOICE",
  osat: "OSAT",
  "problem-resolution": "PROBLEM RESOLUTION",
  taste: "TASTE",
  "sales-vs-target": "SALES VS. TARGET",
  "spccf-vs-target": "SPCCF vs. TARGET",
  "bob-over-all-score": "BOB OVER ALL SCORE",
  "knowledge-force": "KNOWLEDGE FORCE",
  gp: "GP",
  col: "COL",
  "business-skills": "Business Skills",
  "communication-skills": "Communication Skills",
  "leadership-skills": "Leadership Skills",
  "decision-making": "Decision Making",
  "work-standards-organizational-skills":
    "Work Standards & Organizational Skills",
  others: "Others",
  "kra-1": "KRA #1",
  "kra-2": "KRA #2",
  "kra-3": "KRA #3",
  "kra-4": "KRA #4",
  "kra-5": "KRA #5",
};

const interventions: { [key: string]: string } = {
  "attendance-to-training": "Attendance to Training",
  "participate-in-special-projects-or-assignments":
    "Participate in Special Projects or Assignments",
  "on-the-job-training-ojt": "On-the-Job Training (OJT)",
  "job-rotation": "Job Rotation",
  "is-provides-coaching-and-feedback": "IS provides coaching and feedback",
  "participate-in-prof-organizations": "Participate in Prof. Organizations",
  "attendance-to-expo-conference": "Attendance to Expo/Conference",
  "other-resources-provided-by-is": "Other resources provided by IS",
};
const formGroupTypes: { [key: string]: number } = {
  NONE: 0,
  BOB: 1,
  KRA: 2,
};

export default {
  apiURL: process.env.REACT_APP_API_URL ?? "http://localhost:8000",
  authTokenKey: "PMS-AUTH-KEY",

  subitemChoiceTypes,
  rateStatusesLabels,

  developmentPlans,
  interventions,

  formHeaderTypes,
  formGroupTypes,
};
