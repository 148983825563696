import React, { useState, useEffect } from "react";
import { get } from "lodash";
import { Controller, useFormContext, useFieldArray } from "react-hook-form";
import classnames from "classnames";
import PropTypes from "prop-types";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  InputGroup,
  Collapse,
  Button,
  Alert,
} from "reactstrap";
import FormTemplateSubItem from "./FormTemplateSubItem";
import FormTemplateSubItemScoreChoice from "./FormTemplateSubItemScoreChoice";
import { FormTemplateInput } from "../../../../../types";

interface Props {
  groupIndex: number;
  index: number;
  onRemove: () => void;
}

const FormTemplateItem: React.FC<Props> = (props) => {
  const { index, groupIndex, onRemove } = props;
  const form = useFormContext<FormTemplateInput>();

  const errors = form.errors;

  const [collapseOpen, setCollapseOpen] = useState(true);
  const [showChoices, setShowChoices] = useState(false);

  const control = form.control;

  const { fields, append, remove } = useFieldArray({
    control,
    name: `groups[${groupIndex}].items[${index}].subitems`,
  });

  const {
    fields: choicesFields,
    append: choicesAppend,
    remove: choicesRemove,
  } = useFieldArray({
    control,
    name: `groups[${groupIndex}].items[${index}].subitemsScoreChoices`,
  });

  useEffect(() => {
    console.log(fields.length);
    setShowChoices(fields.length > 0);
  }, [fields]);

  const handleRemove = (i: number): void => {
    remove(i);
  };

  const handleChoiceRemove = (i: number): void => {
    choicesRemove(i);
  };

  return (
    <div className="accordion">
      <Card className="card-plain">
        <CardHeader
          role="tab"
          className="bg-default text-white"
          onClick={() => setCollapseOpen(!collapseOpen)}
        >
          <h5 className="mb-0 text-white d-flex justify-content-between">
            <span>Item #{index + 1}</span>
            {index > 0 ? (
              <Button
                className="btn btn-danger mr-3"
                size="sm"
                onClick={onRemove}
              >
                delete
              </Button>
            ) : null}
          </h5>
        </CardHeader>
        <Collapse role="tabpanel" isOpen={collapseOpen}>
          <CardBody>
            <Row>
              <Col xl="12">
                <FormGroup>
                  <label className="form-control-label">Name</label>
                  <InputGroup className={classnames("input-group-merge")}>
                    <Controller
                      name={`groups[${groupIndex}].items[${index}].name`}
                      control={form.control}
                      as={
                        <Input
                          invalid={get(
                            errors,
                            `groups[${groupIndex}].items[${index}].name`
                          )}
                          placeholder=""
                          type="text"
                        />
                      }
                    />
                  </InputGroup>
                  {get(errors, `groups[${groupIndex}].items[${index}].name`) ? (
                    <span className="text-danger mt-3 d-block">
                      {
                        get(
                          errors,
                          `groups[${groupIndex}].items[${index}].name`
                        ).message
                      }
                    </span>
                  ) : null}
                </FormGroup>
                <div className="d-flex">
                  <FormGroup className="flex-grow-1 mr-1">
                    <label className="form-control-label">Weight</label>
                    <InputGroup className={classnames("input-group-merge")}>
                      <Controller
                        name={`groups[${groupIndex}].items[${index}].weight`}
                        control={form.control}
                        as={
                          <Input
                            invalid={get(
                              errors,
                              `groups[${groupIndex}].items[${index}].name`
                            )}
                            placeholder=""
                            type="text"
                          />
                        }
                      />
                    </InputGroup>
                    {get(
                      errors,
                      `groups[${groupIndex}].items[${index}].weight`
                    ) ? (
                      <span className="text-danger mt-3 d-block">
                        {
                          get(
                            errors,
                            `groups[${groupIndex}].items[${index}].weight`
                          ).message
                        }
                      </span>
                    ) : null}
                  </FormGroup>
                  <FormGroup className="flex-grow-1 ml-1">
                    <label className="form-control-label">Max Score</label>
                    <InputGroup className={classnames("input-group-merge")}>
                      <Controller
                        name={`groups[${groupIndex}].items[${index}].maxScore`}
                        control={form.control}
                        as={<Input placeholder="" type="text" />}
                      />
                    </InputGroup>
                    {get(
                      errors,
                      `groups[${groupIndex}].items[${index}].maxScore`
                    ) ? (
                      <span className="text-danger mt-3 d-block">
                        {
                          get(
                            errors,
                            `groups[${groupIndex}].items[${index}].maxScore`
                          ).message
                        }
                      </span>
                    ) : null}
                  </FormGroup>
                </div>
                <hr />
                <FormGroup className="">
                  <label className="form-control-label d-flex justify-content-between">
                    <span>Subitems</span>
                    <Button
                      className="btn-default"
                      onClick={(e) => append({ name: "" })}
                      size="sm"
                    >
                      Add
                    </Button>
                  </label>
                  {fields.map((item, subitemIndex) => {
                    return (
                      <FormTemplateSubItem
                        key={item.key}
                        groupIndex={groupIndex}
                        itemIndex={index}
                        index={subitemIndex}
                        onRemove={() => handleRemove(subitemIndex)}
                      />
                    );
                  })}
                  {fields.length === 0 ? (
                    <Alert color="secondary">
                      <strong>There are no subitems defined</strong> -- Click
                      the <strong>Add</strong> button to create one.
                    </Alert>
                  ) : null}
                </FormGroup>

                <hr />
                {showChoices ? (
                  <FormGroup className="">
                    <label className="form-control-label d-flex justify-content-between">
                      <span>Subitem Score Choices</span>
                      <Button
                        className="btn-default"
                        onClick={(e) => choicesAppend({ name: "", score: 0 })}
                        size="sm"
                      >
                        Add
                      </Button>
                    </label>

                    {choicesFields.map((item, subitemIndex) => {
                      return (
                        <FormTemplateSubItemScoreChoice
                          key={item.key}
                          groupIndex={groupIndex}
                          itemIndex={index}
                          index={subitemIndex}
                          onRemove={() => handleChoiceRemove(subitemIndex)}
                        />
                      );
                    })}

                    {choicesFields.length === 0 ? (
                      <Alert color="secondary">
                        <strong>
                          There are no subitems score choices defined
                        </strong>{" "}
                        -- Click the <strong>Add</strong> button to create one.
                      </Alert>
                    ) : null}

                    {get(
                      errors,
                      `groups[${groupIndex}].items[${index}].subitemsScoreChoices`
                    ) ? (
                      <span className="text-danger mt-3 d-block">
                        {
                          get(
                            errors,
                            `groups[${groupIndex}].items[${index}].subitemsScoreChoices`
                          ).message
                        }
                      </span>
                    ) : null}
                  </FormGroup>
                ) : null}
              </Col>
            </Row>
          </CardBody>
        </Collapse>
      </Card>
    </div>
  );
};

FormTemplateItem.propTypes = {
  groupIndex: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  onRemove: PropTypes.any.isRequired,
};

export default FormTemplateItem;
