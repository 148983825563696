import { Rate } from "./models/Rate";
import { types, flow, Instance, onSnapshot, cast } from "mobx-state-tree";
import { createContext, useContext } from "react";
import { AxiosResponse } from "axios";

import axios from "../utils/axios";
import { LoginResponse, LoginParams, RateType } from "./../types";

import { UserAccount } from "./models";

export const RootModel = types
  .model({
    userAccount: UserAccount,
    createRate: Rate,
    showRate: Rate,
  })
  .actions((self) => ({
    login: flow<AxiosResponse<LoginResponse>, any[]>(function* (
      data: LoginParams
    ): Generator<Promise<AxiosResponse<LoginResponse>>> {
      const response = yield axios.post<LoginResponse>(`/api/login`, data);
      return response;
    }),
    setCreateRate(rate: RateType) {
      self.createRate = cast(rate);
    },
    resetCreateRate() {
      self.createRate = cast({});
    },
  }));

export const rootStore = RootModel.create({
  userAccount: UserAccount.create(),
  createRate: Rate.create(),
  showRate: Rate.create(),
});

onSnapshot(rootStore, (snapshot) => console.log("Snapshot: ", snapshot));

export type RootInstance = Instance<typeof RootModel>;
const RootStoreContext = createContext<null | RootInstance>(null);

export const Provider = RootStoreContext.Provider;

export function useMst() {
  const store = useContext(RootStoreContext);
  if (store === null) {
    throw new Error("Store cannot be null");
  }
  return store;
}
