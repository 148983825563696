import { types, flow, applySnapshot, Instance } from "mobx-state-tree";
import { AxiosResponse } from "axios";
import localforage from "localforage";
import axios from "../../utils/axios";
import { User } from "./User";
import {
  UserAccountType,
  FormTemplateHeaderResponse,
  FormTemplateHeadersResponse,
  LoginResponse,
  FormTemplateInput,
  StatusOKResponse,
  UserProfilesResponse,
  UsersResponse,
  RateHeaderResponse,
  BobScoresResponse,
  RateHeadersResponse,
  RateType,
} from "./../../types";
import config from "config";

export const UserAccount = types
  .model({
    token: types.maybe(types.string),
    user: types.maybe(User),
  })
  .actions((self) => {
    const save = (data: UserAccountType): void => {
      self.token = data.token;
      self.user = data.user;
    };

    const getUserAccountUsingToken = flow<AxiosResponse<LoginResponse>, any[]>(
      function* (
        token: string
      ): Generator<Promise<AxiosResponse<LoginResponse>>> {
        const response = yield axios.get<LoginResponse>(`/api/profile/me`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        return response;
      }
    );
    return {
      save,

      getUserAccountUsingToken,

      logout: () => {
        self.token = "";
        self.user = undefined;
        localforage
          .removeItem(config.authTokenKey)
          .then(() => console.log("logged out"));
      },

      getForm: flow<AxiosResponse<FormTemplateHeaderResponse>, any[]>(
        function* (
          id: string
        ): Generator<Promise<AxiosResponse<FormTemplateHeaderResponse>>> {
          const response = yield axios.get<FormTemplateHeaderResponse>(
            `/api/forms/${id}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${self.token}`,
              },
            }
          );
          return response;
        }
      ),

      getUserForms: flow<AxiosResponse<FormTemplateHeadersResponse>, any[]>(
        function* (
          id: number
        ): Generator<Promise<AxiosResponse<FormTemplateHeadersResponse>>> {
          const response = yield axios.get<FormTemplateHeadersResponse>(
            `/api/forms/employees/${id}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${self.token}`,
              },
            }
          );
          return response;
        }
      ),

      getMyForms: flow<AxiosResponse<FormTemplateHeadersResponse>, any[]>(
        function* (): Generator<
          Promise<AxiosResponse<FormTemplateHeadersResponse>>
        > {
          const response = yield axios.get<FormTemplateHeadersResponse>(
            `/api/forms/mine`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${self.token}`,
              },
            }
          );
          return response;
        }
      ),

      saveForm: flow<AxiosResponse<FormTemplateHeaderResponse>, any[]>(
        function* (
          data: FormTemplateInput
        ): Generator<Promise<AxiosResponse<FormTemplateHeaderResponse>>> {
          const response = yield axios.post<FormTemplateHeaderResponse>(
            `/api/forms/create`,
            data,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${self.token}`,
              },
            }
          );
          return response;
        }
      ),

      toggleRateeActive: flow<AxiosResponse<any>, any[]>(function* (
        id: string
      ): Generator<Promise<AxiosResponse<FormTemplateHeaderResponse>>> {
        const response = yield axios.post<FormTemplateHeaderResponse>(
          `/api/ratees/${id}/toggle_active`,
          {},
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${self.token}`,
            },
          }
        );
        return response;
      }),

      updateForm: flow<AxiosResponse<FormTemplateHeaderResponse>, any[]>(
        function* (
          id: string,
          data: FormTemplateInput
        ): Generator<Promise<AxiosResponse<FormTemplateHeaderResponse>>> {
          const response = yield axios.put<FormTemplateHeaderResponse>(
            `/api/forms/${id}/edit`,
            data,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${self.token}`,
              },
            }
          );
          return response;
        }
      ),

      deleteForm: flow<AxiosResponse<StatusOKResponse>, any[]>(function* (
        id: string
      ): Generator<Promise<AxiosResponse<StatusOKResponse>>> {
        const response = yield axios.delete<StatusOKResponse>(
          `/api/forms/${id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${self.token}`,
            },
          }
        );
        return response;
      }),

      getMyRatees: flow<AxiosResponse<UserProfilesResponse>, any[]>(
        function* (): Generator<Promise<AxiosResponse<UserProfilesResponse>>> {
          const response = yield axios.get<UserProfilesResponse>(
            `/api/ratees`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${self.token}`,
              },
            }
          );
          return response;
        }
      ),

      getMyRateesUsers: flow<AxiosResponse<UsersResponse>, any[]>(
        function* (): Generator<Promise<AxiosResponse<UsersResponse>>> {
          const response = yield axios.get<UsersResponse>(`/api/ratees/users`, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${self.token}`,
            },
          });
          return response;
        }
      ),

      getEmployeesSelfRatesList: flow<
        AxiosResponse<RateHeadersResponse>,
        any[]
      >(function* (): Generator<Promise<AxiosResponse<RateHeadersResponse>>> {
        const response = yield axios.get<RateHeadersResponse>(
          `/api/self_rates/employees/list`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${self.token}`,
            },
          }
        );
        return response;
      }),

      getMySelfRatesList: flow<AxiosResponse<RateHeadersResponse>, any[]>(
        function* (): Generator<Promise<AxiosResponse<RateHeadersResponse>>> {
          const response = yield axios.get<RateHeadersResponse>(
            `/api/self_rates/list`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${self.token}`,
              },
            }
          );
          return response;
        }
      ),

      getMyRatesList: flow<AxiosResponse, any[]>(
        function* (page): Generator<Promise<AxiosResponse<RateHeadersResponse>>> {
          const response = yield axios.get<RateHeadersResponse>(
            `/api/rates/list?page=${page}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${self.token}`,
              },
            }
          );
          return response;
        }
      ),

      getRate: flow<AxiosResponse<RateHeaderResponse>, any[]>(function* (
        id: string
      ): Generator<Promise<AxiosResponse<RateHeaderResponse>>> {
        const response = yield axios.get<RateHeaderResponse>(
          `/api/rate_employee/${id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${self.token}`,
            },
          }
        );
        return response;
      }),

      saveRate: flow<AxiosResponse<RateHeaderResponse>, any[]>(function* (
        data: RateType
      ): Generator<Promise<AxiosResponse<RateHeaderResponse>>> {
        const response = yield axios.post<RateHeaderResponse>(
          `/api/rate_employee`,
          data,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${self.token}`,
            },
          }
        );
        return response;
      }),

      updateRate: flow<AxiosResponse<RateHeaderResponse>, any[]>(function* (
        id: string,
        data: RateType
      ): Generator<Promise<AxiosResponse<RateHeaderResponse>>> {
        const response = yield axios.put<RateHeaderResponse>(
          `/api/rate_employee/${id}/edit`,
          data,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${self.token}`,
            },
          }
        );
        return response;
      }),

      deleteRate: flow<AxiosResponse<StatusOKResponse>, any[]>(function* (
        id: string
      ): Generator<Promise<AxiosResponse<StatusOKResponse>>> {
        const response = yield axios.delete<StatusOKResponse>(
          `/api/rate_employee/${id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${self.token}`,
            },
          }
        );
        return response;
      }),

      getMyApprovalsList: flow<AxiosResponse<RateHeadersResponse>, any[]>(
        function* (
          year: string,
          period: string
        ): Generator<Promise<AxiosResponse<RateHeadersResponse>>> {
          const response = yield axios.get<RateHeadersResponse>(
            `/api/approvals`,
            {
              params: { year, period },
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${self.token}`,
              },
            }
          );
          return response;
        }
      ),

      getForDiscussionRates: flow<AxiosResponse<RateHeadersResponse>, any[]>(
        function* (
          year: string,
          period: string
        ): Generator<Promise<AxiosResponse<RateHeadersResponse>>> {
          const response = yield axios.get<RateHeadersResponse>(
            `/api/approvals/for_discussion`,
            {
              params: { year, period },
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${self.token}`,
              },
            }
          );
          return response;
        }
      ),

      getAcknowledgedRates: flow<AxiosResponse<RateHeadersResponse>, any[]>(
        function* (
          year: string,
          period: string
        ): Generator<Promise<AxiosResponse<RateHeadersResponse>>> {
          const response = yield axios.get<RateHeadersResponse>(
            `/api/approvals/acknowledged`,
            {
              params: {
                year,
                period,
              },
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${self.token}`,
              },
            }
          );
          return response;
        }
      ),
      convertSelfRate: flow<AxiosResponse<RateHeaderResponse>, any[]>(
        function* (
          id: string
        ): Generator<Promise<AxiosResponse<RateHeaderResponse>>> {
          const response = yield axios.get<RateHeaderResponse>(
            `/api/convert_self_rate/${id}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${self.token}`,
              },
            }
          );
          return response;
        }
      ),

      approveRate: flow<AxiosResponse<RateHeaderResponse>, any[]>(function* (
        id: string
      ): Generator<Promise<AxiosResponse<RateHeaderResponse>>> {
        const response = yield axios.post<RateHeaderResponse>(
          `/api/approvals/rate/${id}/approve`,
          {},
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${self.token}`,
            },
          }
        );
        return response;
      }),

      rejectRate: flow<AxiosResponse<RateHeaderResponse>, any[]>(function* (
        id: string,
        remarks: string
      ): Generator<Promise<AxiosResponse<RateHeaderResponse>>> {
        const response = yield axios.post<RateHeaderResponse>(
          `/api/approvals/rate/${id}/reject`,
          { remarks },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${self.token}`,
            },
          }
        );
        return response;
      }),

      approveAll: flow<AxiosResponse<RateHeaderResponse>, any[]>(
        function* (): Generator<Promise<AxiosResponse<RateHeaderResponse>>> {
          const response = yield axios.post<RateHeaderResponse>(
            `/api/approvals/approve_all`,
            {},
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${self.token}`,
              },
            }
          );
          return response;
        }
      ),

      saveIDP: flow<AxiosResponse<RateHeaderResponse>, any[]>(function* (
        id: string,
        data: RateType
      ): Generator<Promise<AxiosResponse<RateHeaderResponse>>> {
        const response = yield axios.post<RateHeaderResponse>(
          `/api/rate_employee/${id}/idp`,
          data,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${self.token}`,
            },
          }
        );
        return response;
      }),

      savePIP: flow<AxiosResponse<RateHeaderResponse>, any[]>(function* (
        id: string,
        data: RateType
      ): Generator<Promise<AxiosResponse<RateHeaderResponse>>> {
        const response = yield axios.post<RateHeaderResponse>(
          `/api/rate_employee/${id}/pip`,
          data,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${self.token}`,
            },
          }
        );
        return response;
      }),

      getBobScores: flow<AxiosResponse<BobScoresResponse>, any[]>(function* (
        bobVersion = 1
      ): Generator<Promise<AxiosResponse<BobScoresResponse>>> {
        const response = yield axios.get<BobScoresResponse>(`/api/bobscores`, {
          params: { version: bobVersion },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${self.token}`,
          },
        });
        return response;
      }),

      getUserBobScores: flow<AxiosResponse<BobScoresResponse>, any[]>(
        function* (
          id: number,
          bobVersion: string
        ): Generator<Promise<AxiosResponse<BobScoresResponse>>> {
          const response = yield axios.get<BobScoresResponse>(
            `/api/bobscores/${id}?version=${bobVersion}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${self.token}`,
              },
            }
          );
          return response;
        }
      ),

      resendEmailRate: flow<AxiosResponse<StatusOKResponse>, any[]>(function* (
        email: string,
        rate: RateType
      ): Generator<Promise<AxiosResponse<StatusOKResponse>>> {
        const response = yield axios.post<StatusOKResponse>(
          `/api/rates/${rate.id}/resend/`,
          {
            email,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${self.token}`,
            },
          }
        );
        return response;
      }),
    };
  });
