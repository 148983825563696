import React from "react";
import { get } from "lodash";
import { Controller, useFormContext, useFieldArray } from "react-hook-form";
import classnames from "classnames";
import PropTypes from "prop-types";
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button,
} from "reactstrap";
import { FormTemplateInput } from "../../../../../types";

interface Props {
  groupIndex: number;
  itemIndex: number;
  index: number;
  onRemove: () => void;
}

const FormTemplateSubItem: React.FC<Props> = (props) => {
  const form = useFormContext<FormTemplateInput>();
  const { index, itemIndex, groupIndex, onRemove } = props;

  const errors = form.errors;

  return (
    <Row>
      <Col xl="12">
        <FormGroup>
          <InputGroup className={classnames("input-group-merge")}>
            <Controller
              name={`groups[${groupIndex}].items[${itemIndex}].subitems[${index}].name`}
              control={form.control}
              as={
                <Input
                  invalid={
                    !!get(
                      errors,
                      `groups[${groupIndex}].items[${itemIndex}].subitems[${index}].name`
                    )
                  }
                  placeholder="Title"
                  type="text"
                />
              }
            />
            <InputGroupAddon addonType="append">
              <Button className="btn-danger" size="sm" onClick={onRemove}>
                Remove
              </Button>
            </InputGroupAddon>
          </InputGroup>
          {get(
            errors,
            `groups[${groupIndex}].items[${itemIndex}].subitems[${index}].name`
          ) ? (
            <span className="text-danger mt-3 d-block">
              {
                get(
                  errors,
                  `groups[${groupIndex}].items[${itemIndex}].subitems[${index}].name`
                ).message
              }
            </span>
          ) : null}
        </FormGroup>
      </Col>
    </Row>
  );
};

FormTemplateSubItem.propTypes = {
  groupIndex: PropTypes.number.isRequired,
  itemIndex: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  onRemove: PropTypes.any.isRequired,
};

export default FormTemplateSubItem;
