import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import localforage from "localforage";

import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// bootstrap rtl for rtl support page
import "assets/vendor/bootstrap-rtl/bootstrap-rtl.scss";
// plugins styles from node_modules
import "react-perfect-scrollbar/dist/css/styles.css";
import "react-toastify/dist/ReactToastify.css";
// plugins styles downloaded
import "assets/vendor/fullcalendar/dist/fullcalendar.min.css";
import "assets/vendor/sweetalert2/dist/sweetalert2.min.css";
import "assets/vendor/select2/dist/css/select2.min.css";
import "assets/vendor/quill/dist/quill.core.css";
import "assets/vendor/nucleo/css/nucleo.css";

import "assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";
// core styles
import "assets/scss/argon-dashboard-pro-react.scss?v1.1.0";

import "./assets/css/main.css";

import config from "./config";

import { Provider, rootStore } from "./store/RootStore";

import AdminLayout from "layouts/Admin";
import AuthLayout from "layouts/Auth";
import AuthenticatedRoute from "hoc/AuthenticatedRoute";
import { UserAccountType, LoginResponse } from "types";
import { AxiosResponse } from "axios";
import Loading from "components/Loading";

const App: React.FC = () => {
  const [isPageLoading, setIsPageLoading] = useState(true);
  useEffect(() => {
    localforage
      .getItem<UserAccountType>(config.authTokenKey)
      .then((data: UserAccountType) => {
        if (data && data.token) {
          return rootStore.userAccount.getUserAccountUsingToken(data.token);
        }
        throw new Error("No token saved");
      })
      .then((response: AxiosResponse<LoginResponse>) => {
        const data = response.data.data as UserAccountType;
        rootStore.userAccount.save(data);
        setIsPageLoading(false);
      })
      .catch((err) => {
        rootStore.userAccount.logout();
        setIsPageLoading(false);
      });
  }, []);

  if (isPageLoading) {
    return <Loading />;
  }

  return (
    <Provider value={rootStore}>
      <BrowserRouter>
        <Switch>
          <Route path="/auth" render={(props) => <AuthLayout {...props} />} />

          <AuthenticatedRoute exact path="/admin/*">
            <AdminLayout />
          </AuthenticatedRoute>

          <AuthenticatedRoute exact path="/">
            <Redirect to="/admin/ratees" />
          </AuthenticatedRoute>
        </Switch>
      </BrowserRouter>
    </Provider>
  );
};

export default App;
