import React, { useState, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardHeader,
  Table,
} from "reactstrap";
import { useMst } from "store/RootStore";
import { UserType, UserProfileType } from "types";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { formatDate } from "@fullcalendar/core";

const RateesList: React.FC = () => {
  const store = useMst();
  const history = useHistory();
  const [pageLoading, setPageLoading] = useState(false);

  const [ratees, setRatees] = useState<UserProfileType[]>([]);

  const loadData = () => {
    setPageLoading(true);
    store.userAccount
      .getMyRatees()
      .then((resp) => resp.data.data)
      .then((data) => setRatees(data))
      .then(() => setPageLoading(false));
  };

  useEffect(() => {
    loadData();
  }, []);

  const handleToggleActive = async (id: number) => {
    await store.userAccount.toggleRateeActive(id);
    loadData();
  };

  return (
    <>
      <div className="header bg-default pb-6">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col lg="6" xs="7">
                <h6 className="h2 text-white d-inline-block mb-0">My Ratees</h6>
              </Col>
            </Row>
          </div>
        </Container>
      </div>

      <Container className="mt--6" fluid>
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <h5 className="h3 mb-0"></h5>
              </CardHeader>

              <CardBody>
                {pageLoading ? (
                  <Skeleton count={5}></Skeleton>
                ) : (
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th className="sort" data-sort="name" scope="col">
                          USER ID
                        </th>
                        <th className="sort" data-sort="name" scope="col">
                          Store
                        </th>
                        <th className="sort" data-sort="name" scope="col">
                          Name
                        </th>
                        <th className="sort" data-sort="name" scope="col">
                          Position
                        </th>
                        <th className="sort" data-sort="name" scope="col">
                          Date Hired
                        </th>
                        <th scope="col" className="text-right"></th>
                      </tr>
                    </thead>
                    <tbody className="list">
                      {ratees.map((ratee) => {
                        return (
                          <tr key={ratee.userId}>
                            <td>{ratee.userId}</td>
                            <td>{ratee.storeNumber}</td>
                            <td>
                              {ratee.firstName} {ratee.lastName}
                            </td>
                            <td>{ratee.positionCode}</td>
                            <td>{ratee.dateHired}</td>
                            <td className="text-right">
                              {ratee.pmsActive ? (
                                <button
                                  className="btn btn-primary"
                                  onClick={() =>
                                    handleToggleActive(ratee.userId)
                                  }
                                >
                                  Active
                                </button>
                              ) : (
                                <button
                                  className="btn btn-default"
                                  onClick={() =>
                                    handleToggleActive(ratee.userId)
                                  }
                                >
                                  Inactive
                                </button>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default RateesList;
