import * as yup from "yup";
import { values } from "lodash";

const schema = yup.object().shape({
  name: yup.string(),
  ratee: yup.number().required("Ratee is required").min(0),
  formTemplate: yup.number().required("Form template is required").min(1),
  year: yup.string().required("Year is required"),
  period: yup.string().required("Period is required"),
  groups: yup.array().of(
    yup.object().shape({
      name: yup.string(),
      weight: yup.number(),
      subtitle: yup.string(),
      subitemLabel: yup.string(),
      items: yup.array().of(
        yup.object().shape({
          name: yup.string().when("isKraSpecific", {
            is: true,
            then: yup.string().required("Name is required"),
            else: yup.string(),
          }),
          weight: yup.number(),
          isKraSpecific: yup.boolean(),
          maxScore: yup.number(),
          subitems: yup.array().of(
            yup.object().shape({
              name: yup.string().when("editable", {
                is: true,
                then: yup.string().required("Name is required"),
                else: yup.string(),
              }),
              editable: yup.boolean(),
              score: yup
                .number()
                .min(0, "Score is required")
                .required("Score is required"),
              remarks: yup.string().required("Remarks is required"),

              subitemsScoreChoices: yup.array().of(
                yup.object().shape({
                  name: yup.string(),
                  score: yup.string(),
                })
              ),
            })
          ),
        })
      ),
    })
  ),
});

export default schema;
