import React from "react";
import { Controller, useFormContext, useFieldArray } from "react-hook-form";
import classnames from "classnames";
import PropTypes from "prop-types";
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button,
} from "reactstrap";

interface Props {
  groupIndex: number;
  itemIndex: number;
  index: number;
  onRemove: () => void;
}

const FormTemplateSubItemScoreChoice: React.FC<Props> = (props) => {
  const form = useFormContext();
  const { index, itemIndex, groupIndex, onRemove } = props;

  return (
    <Row>
      <Col xl="12">
        <FormGroup>
          <div className="d-flex">
            <InputGroup className={classnames("input-group-merge", "mr-1")}>
              <Controller
                name={`groups[${groupIndex}].items[${itemIndex}].subitemsScoreChoices[${index}].name`}
                control={form.control}
                as={<Input placeholder="Label" type="text" />}
              />
            </InputGroup>
            <InputGroup className={classnames("input-group-merge", "ml-1")}>
              <Controller
                name={`groups[${groupIndex}].items[${itemIndex}].subitemsScoreChoices[${index}].score`}
                control={form.control}
                as={<Input placeholder="Score" type="text" />}
              />
              <InputGroupAddon addonType="append">
                <Button className="btn-danger" size="sm" onClick={onRemove}>
                  Remove
                </Button>
              </InputGroupAddon>
            </InputGroup>
          </div>
        </FormGroup>
      </Col>
    </Row>
  );
};

FormTemplateSubItemScoreChoice.propTypes = {
  groupIndex: PropTypes.number.isRequired,
  itemIndex: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  onRemove: PropTypes.any.isRequired,
};

export default FormTemplateSubItemScoreChoice;
