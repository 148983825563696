import React, { useState, useEffect } from "react";
import accounting from "accounting";
import Skeleton from "react-loading-skeleton";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardHeader,
  Table,
  UncontrolledPopover,
  PopoverBody,
  Modal,
} from "reactstrap";
import { useMst } from "store/RootStore";
import { observer } from "mobx-react";
import { useHistory, useParams } from "react-router";
import { RateType } from "types";
import { toast } from "react-toastify";
import { Rate } from "store/models/Rate";
import { applySnapshot, getSnapshot } from "mobx-state-tree";
import config from "config";

const ManagePIP: React.FC = observer(() => {
  const store = useMst();
  const history = useHistory();
  const { id } = useParams();
  const [
    currentSelectedEvaluateModal,
    setCurrentSelectedEvaluateModal,
  ] = useState("");
  const [pageLoading, setPageLoading] = useState(false);

  const rate = store.showRate;

  useEffect(() => {
    setPageLoading(true);
    store.userAccount
      .getRate(id)
      .then((res) => res.data.data)
      .then((data) => {
        applySnapshot(store.showRate, data);
        store.showRate.initializePIPImprovementPlan();
      })
      .catch((e) => console.log(e))
      .then(() => setPageLoading(false));
  }, [id]);

  const group = store.showRate ? store.showRate.groups[0] : null;

  const handleSave = async () => {
    try {
      await store.userAccount.savePIP(store.showRate.uuid, store.showRate);
      toast.success("Successfully saved PIP");
      history.push(`/admin/rate_employee/${store.showRate.uuid}/details`);
    } catch (e) {
      console.log(e);
      toast.error("Failed to save PIP, please try again later");
    }
  };

  return (
    <>
      <div className="header bg-default pb-6">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col lg="6" xs="7">
                <h6 className="h2 text-white d-inline-block mb-0">
                  Manage PIP
                </h6>
              </Col>
              <Col className="text-right" lg="6" xs="5"></Col>
            </Row>
          </div>
        </Container>
      </div>

      <Container className="mt--6" fluid>
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <h5 className="h3 mb-0">
                  {rate?.name} - {rate?.year}
                </h5>
              </CardHeader>
              {pageLoading ? (
                <CardBody>
                  <Skeleton count={5} />
                </CardBody>
              ) : (
                <CardBody>
                  <Table>
                    <tbody>
                      <tr>
                        <td style={{ width: 140 }}>
                          <b>Ratee</b>
                        </td>
                        <td style={{ width: 300 }}>
                          {rate?.rateeUser?.firstName}{" "}
                          {rate?.rateeUser?.lastName}
                        </td>
                        <td style={{ width: 140 }}>
                          <b>Ratee Dept</b>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td style={{ width: 140 }}>
                          <b>Ratee Position</b>
                        </td>
                        <td style={{ width: 300 }}>
                          {rate?.rateeUser?.profile?.position}
                        </td>
                        <td style={{ width: 140 }}>
                          <b>Ratee Employee No</b>
                        </td>
                        <td>{rate?.rateeUser?.profile?.employeeNumber}</td>
                      </tr>
                      <tr>
                        <td style={{ width: 140 }}>
                          <b>Ratee Division</b>
                        </td>
                        <td style={{ width: 300 }}></td>
                        <td>
                          <b>Period</b>
                        </td>
                        <td>
                          {rate?.period == "1" ? "First" : "Second"} (
                          {rate?.year})
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                  <hr />
                  <Table>
                    <tbody>
                      <tr>
                        <td style={{ width: 140 }}>
                          <b>Rater</b>
                        </td>
                        <td style={{ width: 300 }}>
                          {rate?.rater?.firstName} {rate?.rater?.lastName}
                        </td>
                        <td style={{ width: 140 }}>
                          <b>Rater Dept</b>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td style={{ width: 140 }}>
                          <b>Rater Position</b>
                        </td>
                        <td>{rate?.rater?.profile?.position}</td>
                        <td style={{ width: 140 }}>
                          <b>Rater Employee No</b>
                        </td>
                        <td>{rate?.rater?.profile?.employeeNumber}</td>
                      </tr>
                      <tr>
                        <td>
                          <b>Rater Division</b>
                        </td>
                        <td></td>
                        <td>
                          <b>Period</b>
                        </td>
                        <td>
                          {rate?.period == "1" ? "First" : "Second"} (
                          {rate?.year})
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </CardBody>
              )}
            </Card>

            <Card>
              <CardHeader>
                <h5 className="h3 mb-0">Improvement Plan</h5>
              </CardHeader>
              <CardBody>
                <Table className="align-items-center" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th>Areas for Improvement</th>
                      <th>Current Performance</th>
                      <th>Target Performance</th>
                      <th>Select applicable interventions</th>
                      <th>Details</th>
                      <th>Completion Date</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {rate.pipImprovementPlan.map((plan, index) => (
                      <tr key={index}>
                        <td style={{ height: 1 }}>
                          <div className="d-flex flex-column justify-content-around h-100">
                            <select
                              value={plan.improvementPlanType ?? ""}
                              onChange={(e) => {
                                plan.setImprovementPlanType(e.target.value);
                              }}
                              className="form-control"
                            >
                              <option value="">None</option>
                              {Object.keys(config.developmentPlans).map(
                                (key) => (
                                  <option key={key} value={key}>
                                    {config.developmentPlans[key]}
                                  </option>
                                )
                              )}
                            </select>
                          </div>
                        </td>
                        <td>
                          <input
                            type="number"
                            value={plan.currentPerformance ?? ""}
                            onChange={(e) => {
                              plan.setCurrentPerformance(
                                parseFloat(e.target.value)
                              );
                            }}
                            className="form-control"
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            value={plan.targetPerformance ?? ""}
                            onChange={(e) => {
                              plan.setTargetPerformance(
                                parseFloat(e.target.value)
                              );
                            }}
                            className="form-control"
                          />
                        </td>
                        <td>
                          {plan.interventions.map((intervention, i) => (
                            <select
                              value={intervention.interventionType ?? ""}
                              onChange={(e) =>
                                intervention.setInterventionType(e.target.value)
                              }
                              key={i}
                              className="form-control mb-2"
                            >
                              <option value="">None</option>
                              {Object.keys(config.interventions).map((key) => (
                                <option key={key} value={key}>
                                  {config.interventions[key]}
                                </option>
                              ))}
                            </select>
                          ))}
                        </td>
                        <td style={{ minWidth: 400 }}>
                          {plan.interventions.map((intervention, i) => (
                            <textarea
                              key={i}
                              rows={2}
                              value={intervention.remarks}
                              onChange={(e) =>
                                intervention.setRemarks(e.target.value)
                              }
                              className="form-control mb-2"
                            />
                          ))}
                        </td>
                        <td style={{ height: 1 }}>
                          <div className="d-flex flex-column justify-content-around h-100">
                            {plan.interventions.map((intervention, i) => (
                              <input
                                key={i}
                                type="text"
                                value={intervention.completionDate}
                                onChange={(e) =>
                                  intervention.setCompletionDate(e.target.value)
                                }
                                className="form-control mb-2"
                              />
                            ))}
                          </div>
                        </td>
                        <td>
                          <button
                            className="btn btn-primary"
                            onClick={() =>
                              setCurrentSelectedEvaluateModal(
                                `modal-evaluate-${index}`
                              )
                            }
                          >
                            Evaluate
                          </button>
                          <Modal
                            style={{ maxWidth: 850, minHeight: 500 }}
                            className="modal-dialog-centered"
                            isOpen={
                              currentSelectedEvaluateModal ===
                              `modal-evaluate-${index}`
                            }
                          >
                            <div className="modal-body">
                              <Table>
                                <thead>
                                  <tr>
                                    <th>Review on</th>
                                    <th>Objectives</th>
                                    <th>Rating</th>
                                    <th>Justify Rating</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {plan.monthlyEvaluations.map(
                                    (monthlyEval, i) => (
                                      <tr key={i}>
                                        <td>
                                          After {monthlyEval.monthNumber} month
                                        </td>
                                        <td>
                                          {monthlyEval.evaluations.map(
                                            (evaluation, ind) => (
                                              <div key={ind}>
                                                <input
                                                  type="text"
                                                  className="form-control mb-3"
                                                  value={evaluation.objective}
                                                  onChange={(e) =>
                                                    evaluation.setObjective(
                                                      e.target.value
                                                    )
                                                  }
                                                ></input>
                                              </div>
                                            )
                                          )}
                                        </td>
                                        <td>
                                          {monthlyEval.evaluations.map(
                                            (evaluation, ind) => (
                                              <div key={ind}>
                                                <select
                                                  className="form-control mb-3"
                                                  value={evaluation.rating}
                                                  onChange={(e) =>
                                                    evaluation.setRating(
                                                      e.target.value
                                                    )
                                                  }
                                                >
                                                  <option value="">None</option>
                                                  <option value="below-target">
                                                    Below Target
                                                  </option>
                                                  <option value="on-target">
                                                    On Target
                                                  </option>
                                                </select>
                                              </div>
                                            )
                                          )}
                                        </td>
                                        <td>
                                          {monthlyEval.evaluations.map(
                                            (evaluation, ind) => (
                                              <div key={ind}>
                                                <textarea
                                                  rows={1}
                                                  value={evaluation.remarks}
                                                  className="form-control mb-3"
                                                  onChange={(e) =>
                                                    evaluation.setRemarks(
                                                      e.target.value
                                                    )
                                                  }
                                                ></textarea>
                                              </div>
                                            )
                                          )}
                                        </td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </Table>
                            </div>
                            <div className="modal-footer">
                              <button
                                className="btn btn-default"
                                onClick={() =>
                                  setCurrentSelectedEvaluateModal("")
                                }
                              >
                                Save & Close
                              </button>
                            </div>
                          </Modal>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <Button
                  onClick={() => handleSave()}
                  className="btn btn-default"
                >
                  Save
                </Button>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
});

export default ManagePIP;
