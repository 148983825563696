import React, { useState, useEffect } from "react";
import * as yup from "yup";
import { observer } from "mobx-react";
import { get } from "lodash";
import classnames from "classnames";
import accounting from "accounting";

import {
  Controller,
  useForm,
  useFieldArray,
  FormProvider,
  appendErrors,
} from "react-hook-form";
import { toast } from "react-toastify";
import Select2 from "react-select2-wrapper";
import {
  Row,
  Container,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  InputGroup,
  Table,
  InputGroupText,
  InputGroupAddon,
  Input,
} from "reactstrap";
import { useMst } from "store/RootStore";
import { yupResolver } from "@hookform/resolvers";
import { useHistory, useParams } from "react-router";

import {
  CreateRateInput,
  UserType,
  UsersResponse,
  FormTemplateHeadersResponse,
  FormTemplateHeaderType,
  RateType,
} from "types";
import { AxiosResponse } from "axios";
import Loading from "components/Loading";
import Skeleton from "react-loading-skeleton";
import CreateRateGroup from "./components/CreateRateGroup";
import { convertFormToRate } from "utils/helpers";
import schema from "./schema";
import { cast, getSnapshot } from "mobx-state-tree";
import { Rate } from "store/models/Rate";

const EditRate: React.FC = observer(() => {
  const store = useMst();
  const history = useHistory();
  const { id } = useParams();
  const [pageLoading, setPageLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [ratees, setRatees] = useState<UserType[]>([]);
  const [errors, setErrors] = useState<any>({});
  const [rateesFormatted, setRateesFormatted] = useState<
    {
      id: number;
      text: string;
    }[]
  >([]);
  const [forms, setForms] = useState<FormTemplateHeaderType[]>([]);
  const [formsFormatted, setFormsFormatted] = useState<
    {
      id: number;
      text: string;
    }[]
  >([]);

  useEffect(() => {
    const r = ratees.map((ratee) => ({
      id: ratee.id,
      text:
        ratee && ratee.profile
          ? `${ratee.profile.firstName} ${ratee.profile.lastName}`
          : "",
    }));
    setRateesFormatted(r);
  }, [ratees]);

  useEffect(() => {
    const r = forms.map((form) => ({
      id: form.id,
      text: form.name,
    }));
    setFormsFormatted(r);
  }, [forms]);

  useEffect(() => {
    setPageLoading(true);
    store.userAccount
      .getRate(id)
      .then((res) => res.data.data)
      .then((rate) => {
        store.setCreateRate(rate);
        return rate;
      })
      .then((rate) => {
        return Promise.all<
          AxiosResponse<UsersResponse>,
          AxiosResponse<FormTemplateHeadersResponse>
        >([
          store.userAccount.getMyRateesUsers(),
          store.userAccount.getUserForms(rate.ratee),
        ]);
      })
      .then((resp) => {
        setRatees(resp[0].data.data);
        setForms(resp[1].data.data);
      })
      .then(() => setPageLoading(false));
  }, [id]);

  const handleChangeFormTemplate = (formTemplate: number) => {
    const form = forms.filter((f) => f.id == store.createRate.formTemplate)[0];
    if (form) {
      const r = convertFormToRate(form);
      store.createRate.setName(r.name);
      store.createRate.setType(r.type);
      store.createRate.setGroups(r.groups);
    }
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();

    const data = getSnapshot(store.createRate);
    try {
      setIsSubmitting(true);
      await schema.validate(data, { abortEarly: false });

      store.createRate.computeTotalScores();

      const resp = store.userAccount.updateRate(id, store.createRate);
      toast.success("Successfully saved rate");
      setIsSubmitting(false);
      history.push("/admin/rates/list");
    } catch (e) {
      console.log(errors);
      setIsSubmitting(false);
      const newErrors: { [key: string]: string } = {};
      (e as yup.ValidationError).inner.forEach(function (er) {
        newErrors[er.path] = er.message;
      });

      setErrors(newErrors);
    }
  };

  return (
    <>
      <div className="header bg-default pb-6">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col lg="6" xs="7">
                <h6 className="h2 text-white d-inline-block mb-0">
                  Rate Employee
                </h6>
              </Col>
              <Col className="text-right" lg="6" xs="5"></Col>
            </Row>
          </div>
        </Container>
      </div>
      <form onSubmit={onSubmit}>
        <Container className="mt--6" fluid>
          <Row>
            <Col xl="12">
              <Card>
                <CardHeader>
                  <h5 className="h3 mb-0">Rate Details</h5>
                </CardHeader>
                {pageLoading ? (
                  <CardBody>
                    <Skeleton count={5} />
                  </CardBody>
                ) : (
                  <CardBody>
                    <FormGroup>
                      <label className="form-control-label">Year</label>
                      <InputGroup className={classnames("input-group-merge")}>
                        <select
                          className="form-control"
                          value={store.createRate.year}
                          onChange={(e: any) => {
                            store.createRate.setYear(e.target.value);
                          }}
                        >
                          <option value="2024">2024</option>
                          <option value="2023">2023</option>
                          <option value="2022">2022</option>
                          <option value="2021">2021</option>
                          <option value="2020">2020</option>
                        </select>
                      </InputGroup>
                      {errors["year"] ? (
                        <span className="text-danger mt-3 d-block">
                          Please select year
                        </span>
                      ) : null}
                    </FormGroup>
                    <FormGroup>
                      <label className="form-control-label">Period</label>
                      <InputGroup className={classnames("input-group-merge")}>
                        <select
                          className="form-control"
                          value={store.createRate.period}
                          onChange={(e: any) => {
                            store.createRate.setPeriod(e.target.value);
                          }}
                        >
                          <option value="1">First</option>
                          <option value="2">Second</option>
                        </select>
                      </InputGroup>
                      {errors["period"] ? (
                        <span className="text-danger mt-3 d-block">
                          Please select a period
                        </span>
                      ) : null}
                    </FormGroup>
                    <FormGroup>
                      <label className="form-control-label">Ratee</label>
                      <InputGroup className={classnames("input-group-merge")}>
                        <select
                          className="form-control"
                          value={store.createRate.ratee}
                          onChange={(e: any) => {
                            store.createRate.setRatee(parseInt(e.target.value));
                          }}
                        >
                          <option>Please select a ratee</option>
                          {rateesFormatted.map((r) => (
                            <option key={r.id} value={r.id}>
                              {r.text}
                            </option>
                          ))}
                        </select>
                      </InputGroup>
                      {errors["ratee"] ? (
                        <span className="text-danger mt-3 d-block">
                          Please select a ratee
                        </span>
                      ) : null}
                    </FormGroup>
                    {false && (
                      <FormGroup>
                        <label className="form-control-label">
                          Form Template
                        </label>
                        <InputGroup className={classnames("input-group-merge")}>
                          <select
                            className="form-control"
                            value={store.createRate.formTemplate}
                            onChange={(e: any) => {
                              const value = parseInt(e.target.value);
                              store.createRate.setFormTemplate(value);
                              handleChangeFormTemplate(value);
                            }}
                          >
                            <option>Please select a form</option>
                            {formsFormatted.map((r) => (
                              <option key={r.id} value={r.id}>
                                {r.text}
                              </option>
                            ))}
                          </select>
                        </InputGroup>
                        {errors["formTemplate"] ? (
                          <span className="text-danger mt-3 d-block">
                            Please select a form template
                          </span>
                        ) : null}
                      </FormGroup>
                    )}
                  </CardBody>
                )}
              </Card>

              {store.createRate.groups.map((group, index) => {
                return (
                  <CreateRateGroup
                    key={index}
                    errors={errors}
                    group={group}
                    ratee={store.createRate.rateeUser}
                    index={index}
                    year={store.createRate.year}
                  />
                );
              })}
            </Col>

            {store.createRate ? (
              <Col xl="12" l="12">
                <Card>
                  <CardHeader>
                    <div className="d-flex justify-content-between">
                      <h5 className="h3 mb-0">OVERALL RATING</h5>
                      <h5>
                        {store.createRate.overallScore >= 3
                          ? "ON TARGET"
                          : "BELOW TARGET"}
                      </h5>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <Table>
                      <tbody>
                        {store.createRate.groups.map((d) => {
                          return (
                            <tr key={d.name}>
                              <td>
                                {d.name} ({d.weight}%)
                              </td>
                              <td>{accounting.formatNumber(d.score, 2)}</td>
                            </tr>
                          );
                        })}
                        <tr>
                          <td>OVERALL SCORE(100%)</td>
                          <td>
                            {accounting.formatNumber(
                              store.createRate.overallScore,
                              2
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
              </Col>
            ) : null}

            <Col xl="12" l="12">
              <Card>
                <CardHeader>
                  <h5 className="h3 mb-0">Actions</h5>
                </CardHeader>

                <CardBody>
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    htmlType="submit"
                    className="btn-default mr-2"
                  >
                    {isSubmitting ? "Saving changes..." : "Save"}
                  </Button>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </form>
    </>
  );
});

export default EditRate;
