import React, { useState } from "react";
// react library for routing
import { NavLink as NavLinkRRD, Link, useHistory } from "react-router-dom";
// nodejs library that concatenates classes
import classnames from "classnames";
// nodejs library to set properties for components
import PropTypes, { any } from "prop-types";
// react library that creates nice scrollbar on windows devices
import PerfectScrollbar from "react-perfect-scrollbar";
// reactstrap components
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
} from "reactstrap";
import {
  AppRoutes,
  AppRouteCollection,
  AppRouteView,
  SidebarLogo,
} from "types";

interface Props {
  routes: AppRoutes;
  toggleSidenav: (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  sidenavOpen: boolean;
  logo: SidebarLogo;
}

const getCollapseInitialState = (routes: AppRoutes) => {
  for (let i = 0; i < routes.length; i++) {
    const route = routes[i] as AppRouteCollection;
    if (route.collapse && getCollapseInitialState(route.views)) {
      return true;
    }
    const routeView = routes[i] as AppRouteView;
    if (window.location.href.indexOf(routeView.path) !== -1) {
      return true;
    }
  }
  return false;
};

const getCollapseStates = (routes: AppRoutes) => {
  let initialState: { [key: string]: boolean } = {};
  routes.map((prop, key) => {
    const row = prop as AppRouteCollection;
    if (row.collapse) {
      initialState = {
        [row.state]: getCollapseInitialState(row.views),
        ...getCollapseStates(row.views),
        ...initialState,
      };
    }
    return null;
  });
  return initialState;
};

const Sidebar: React.FC<Props> = (props) => {
  const [collapseOpen, setCollapseOpen] = useState(false);
  const [routeState, setRouteState] = useState(getCollapseStates(props.routes));
  const history = useHistory();

  const pathname = history.location.pathname;

  const activeRoute = (routeName: string) => {
    return pathname.indexOf(routeName) > -1 ? "active" : "";
  };

  const onMouseEnterSidenav = () => {
    if (!document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.add("g-sidenav-show");
    }
  };

  const onMouseLeaveSidenav = () => {
    if (!document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-show");
    }
  };

  const toggleCollapse = () => {
    setCollapseOpen(!collapseOpen);
  };

  const closeCollapse = () => {
    setCollapseOpen(false);
  };

  // this is used on mobile devices, when a user navigates
  // the sidebar will autoclose
  const closeSidenav = () => {
    if (window.innerWidth < 1200) {
      props.toggleSidenav();
    }
  };

  // this function creates the links and collapses that appear in the sidebar (left menu)
  const createLinks = (routes: AppRoutes) => {
    return routes.map((prop, key) => {
      const route = prop as AppRouteCollection;
      const routeView = prop as AppRouteView;
      if (route.redirect) {
        return null;
      }
      if (route.collapse) {
        const st: { [key: string]: boolean } = {};
        st[route.state] = !routeState[route.state];
        return (
          <NavItem key={key}>
            <NavLink
              href="#pablo"
              data-toggle="collapse"
              aria-expanded={routeState[route.state]}
              className={classnames({
                active: getCollapseInitialState(route.views),
              })}
              onClick={(e) => {
                e.preventDefault();
                setRouteState({ ...routeState, ...st });
              }}
            >
              {route.icon ? (
                <>
                  <i className={route.icon} />
                  <span className="nav-link-text">{route.name}</span>
                </>
              ) : routeView.miniName ? (
                <>
                  <span className="sidenav-mini-icon">
                    {" "}
                    {routeView.miniName}{" "}
                  </span>
                  <span className="sidenav-normal"> {route.name} </span>
                </>
              ) : null}
            </NavLink>
            <Collapse isOpen={routeState[route.state]}>
              <Nav className="nav-sm flex-column">
                {createLinks(route.views)}
              </Nav>
            </Collapse>
          </NavItem>
        );
      }
      return (
        <NavItem
          className={activeRoute(routeView.layout + routeView.path)}
          key={key}
        >
          <NavLink
            to={routeView.layout + routeView.path}
            activeClassName=""
            onClick={closeSidenav}
            tag={NavLinkRRD}
          >
            {route.icon !== undefined ? (
              <>
                <i className={route.icon} />
                <span className="nav-link-text">{route.name}</span>
              </>
            ) : routeView.miniName !== undefined ? (
              <>
                <span className="sidenav-mini-icon">
                  {" "}
                  {routeView.miniName}{" "}
                </span>
                <span className="sidenav-normal"> {routeView.name} </span>
              </>
            ) : (
              routeView.name
            )}
          </NavLink>
        </NavItem>
      );
    });
  };

  const { routes, logo } = props;
  let navbarBrandProps;
  if (logo && logo.innerLink) {
    navbarBrandProps = {
      to: logo.innerLink,
      tag: Link,
    };
  } else if (logo && logo.outerLink) {
    navbarBrandProps = {
      href: logo.outerLink,
      target: "_blank",
    };
  }
  const scrollBarInner = (
    <div className="scrollbar-inner">
      <div className="sidenav-header d-flex align-items-center">
        <NavbarBrand {...navbarBrandProps} style={{ fontSize: 14 }}>
          Performance <br />
          Management System
        </NavbarBrand>
        <div className="ml-auto">
          <div
            className={classnames("sidenav-toggler d-none d-xl-block", {
              active: props.sidenavOpen,
            })}
            onClick={props.toggleSidenav}
          >
            <div className="sidenav-toggler-inner">
              <i className="sidenav-toggler-line" />
              <i className="sidenav-toggler-line" />
              <i className="sidenav-toggler-line" />
            </div>
          </div>
        </div>
      </div>
      <div className="navbar-inner">
        <Collapse navbar isOpen={true}>
          <Nav navbar>{createLinks(routes)}</Nav>
          <hr className="my-3" />
        </Collapse>
      </div>
    </div>
  );
  return (
    <Navbar
      className={
        "sidenav navbar-vertical navbar-expand-xs navbar-light bg-white fixed-left "
      }
      onMouseEnter={onMouseEnterSidenav}
      onMouseLeave={onMouseLeaveSidenav}
    >
      {navigator.platform.indexOf("Win") > -1 ? (
        <PerfectScrollbar>{scrollBarInner}</PerfectScrollbar>
      ) : (
        scrollBarInner
      )}
    </Navbar>
  );
};

Sidebar.propTypes = {
  toggleSidenav: PropTypes.func.isRequired,
  sidenavOpen: PropTypes.bool.isRequired,
  routes: PropTypes.any,
  logo: any,
};

export default Sidebar;
