import Login from "views/pages/auth/Login";
import AffirmPage from "views/pages/auth/AffirmPage";
import Dashboard from "views/pages/admin/Dashboard";
import FormsList from "views/pages/admin/forms/FormsList";
import CreateForm from "views/pages/admin/forms/CreateForm";
import RateesList from "views/pages/admin/ratees/RateesList";
import MyRatesList from "views/pages/admin/my_rates/MyRatesList";
import MySelfRatesList from "views/pages/admin/my_rates/MySelfRatesList";
import MyApprovalsList from "views/pages/admin/approvals/ApprovalsList";
import ApprovedList from "views/pages/admin/approvals/ApprovedList";
import ManageIDP from "views/pages/admin/my_rates/ManageIDP";
import ManagePIP from "views/pages/admin/my_rates/ManagePIP";

import { AppRouteView, AppRouteCollection } from "types";
import EditForm from "views/pages/admin/forms/EditForm";
import CreateRate from "views/pages/admin/my_rates/CreateRate";
import EditRate from "views/pages/admin/my_rates/EditRate";
import ShowRate from "views/pages/admin/my_rates/ShowRate";
import AcknowledgedList from "views/pages/admin/approvals/AcknowledgedList";
import CreateSelfRate from "views/pages/admin/my_rates/CreateSelfRate";
import EditSelfRate from "views/pages/admin/my_rates/EditSelfRate";
import EmployeeSelfRatesList from "views/pages/admin/my_rates/EmployeeSelfRatesList";

const routes: (AppRouteCollection | AppRouteView)[] = [
  {
    path: "/pms/affirm",
    name: "Affirm",
    miniName: "L",
    component: AffirmPage,
    layout: "/auth",
    redirect: true,
  },
  {
    path: "/login",
    name: "Login",
    miniName: "L",
    component: Login,
    layout: "/auth",
    redirect: true,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    layout: "/admin",
    miniName: "D",
    component: Dashboard,
    icon: "ni ni-app text-primary",
    state: "dashboardsCollapse",
    redirect: true,
  },
  {
    collapse: true,
    redirect: true,
    name: "Form Templates",
    icon: "ni ni-single-copy-04 text-pink",
    state: "formsCollapse",
    views: [
      {
        path: "/forms/list",
        name: "My Forms",
        layout: "/admin",
        miniName: "F",
        component: FormsList,
      },
      {
        path: "/forms/create",
        name: "Create Form",
        layout: "/admin",
        miniName: "C",
        component: CreateForm,
        redirect: true,
        invisible: true,
      },
      {
        path: "/forms/:id/edit",
        name: "My Forms",
        layout: "/admin",
        miniName: "E",
        component: EditForm,
        redirect: true,
        invisible: true,
      },
    ],
  },
  {
    path: "/ratees",
    name: "Ratees",
    layout: "/admin",
    miniName: "R",
    component: RateesList,
    icon: "ni ni-chart-pie-35 text-info",
    state: "dashboardsCollapse",
  },
  {
    collapse: true,
    name: "Self Ratings",
    state: "mySelfRatesCollapse",
    icon: "ni ni-ungroup text-orange",
    views: [
      {
        path: "/self_rates/list",
        name: "My Self Ratings",
        layout: "/admin",
        miniName: "R",
        component: MySelfRatesList,
      },
      {
        path: "/self_rates/create",
        name: "Start Self Rating",
        layout: "/admin",
        miniName: "SR",
        component: CreateSelfRate,
      },
      {
        path: "/self_rates/employees/list",
        name: "Employee Self Ratings",
        layout: "/admin",
        miniName: "SR",
        component: EmployeeSelfRatesList,
      },
      {
        path: "/self_rates/:id/edit",
        name: "Edit Self Rating",
        layout: "/admin",
        miniName: "E",
        component: EditSelfRate,
        redirect: true,
        invisible: true,
      },
    ],
  },
  {
    collapse: true,
    name: "My Ratings",
    state: "myRatesCollapse",
    icon: "ni ni-single-copy-04 text-pink",
    views: [
      {
        path: "/rates/list",
        name: "Team Ratings",
        layout: "/admin",
        miniName: "R",
        component: MyRatesList,
      },
      {
        path: "/rates/create",
        name: "Rate Employee",
        layout: "/admin",
        miniName: "CR",
        component: CreateRate,
      },
      {
        path: "/rate_employee/:id/edit",
        name: "Edit Rating",
        layout: "/admin",
        miniName: "E",
        component: EditRate,
        redirect: true,
        invisible: true,
      },
      {
        path: "/rate_employee/:id/details",
        name: "View Rating Details",
        layout: "/admin",
        miniName: "S",
        component: ShowRate,
        redirect: true,
        invisible: true,
      },
      {
        path: "/rate_employee/:id/idp",
        name: "Create/Edit IDP",
        layout: "/admin",
        miniName: "S",
        component: ManageIDP,
        redirect: true,
        invisible: true,
      },
      {
        path: "/rate_employee/:id/pip",
        name: "Create/Edit PIP",
        layout: "/admin",
        miniName: "S",
        component: ManagePIP,
        redirect: true,
        invisible: true,
      },
    ],
  },

  {
    collapse: true,
    name: "Approvals",
    state: "approvalsCollapse",
    icon: "ni ni-paper-diploma text-default",
    views: [
      {
        path: "/approvals/list",
        name: "My pending approvals",
        layout: "/admin",
        miniName: "R",
        component: MyApprovalsList,
      },
      {
        path: "/approvals/approved/list",
        name: "For discussion",
        layout: "/admin",
        miniName: "A",
        component: ApprovedList,
      },
      {
        path: "/approvals/acknowledged/list",
        name: "Acknowledged",
        layout: "/admin",
        miniName: "A",
        component: AcknowledgedList,
      },
    ],
  },
];

export default routes;
