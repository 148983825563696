const rgm: { name: string; description: string }[] = [
  {
    name: "Cash & Funds Control Program Effectiveness",
    description: `•	Ensures proper implementation of Cash and Funds Control programs
    •	Financial Audit: cash reconciles findings with the internal auditor
    •	Verifies that all deposits (correct amount) have been received by the bank on time`,
  },
  {
    name: "Competence of Management Trainee and RTMs",
    description: `•	Conducts training for Management Trainee and RTMs and must keep their competence level high`,
  },
  {
    name: "Conduct Meetings",
    description: `"•	Conducts store management team meeting and RTMs general meeting at least once a month"`,
  },
  {
    name: "Customer Complaints",
    description: `"•	Ensure immediate corrective and preventive actions on complaints are done
    •	Close customer complaints within 24 if applicable"`,
  },
  {
    name: "Disciplinary Actions",
    description: `"•	Ensures employee benefits program are administered properly"`,
  },
  {
    name: "Managing the Restaurant P&L",
    description: `"•	Hold controllable P&L line items within budget
    •	Reconciles monthly P&L
    •	Discuss P&L to the management team"`,
  },
  {
    name: "New Products and Procedures",
    description: `·         Proper implementation of new products and procedures`,
  },
  {
    name: "Overall Restaurant Management|Supervision",
    description: `"•	Ensures that Assistant Restaurant Managers are the ones doing their respective KRA
    •	Gives the manager constant feedback on their delivery of KRA 
    •	Check if the RTMs is doing exactly what they have been trained to do
    •	Do coaching and counseling for managers and RTMs needed"`,
  },
  {
    name: "Overall Store Management Development Program",
    description: `"•	Ensures an adequate number of staff for Management Team, RTMs and other positions needed to effectively and efficiently operate the restaurant"`,
  },
  {
    name: "Planning",
    description: `·         Prepares Systematic Managerial Analysis (SMA) and SWOT Analysis regularly`,
  },
  {
    name: "Profit & Cost Effectivity and Efficiency",
    description: `"•	Sets restaurant budget
    •	Creates programs to reduce cost
    •	Effectively manage productivity and efficiency in-store operation"`,
  },
  {
    name: "Reports",
    description: `"•	Accomplishes monthly store operating reports such as weekly, mid-month|month end inventory and usages, petty cash, paid out, deposits, and P&L line item contributors
    •	Review and check the accuracy of reports and paper works prepared by assistants"`,
  },
  {
    name: "Restaurant Operations Compliance Check (ROCC) Programs",
    description: `"•	Creates long term store objectives, action plans, and programs for ROCC and improvement and consistency"`,
  },
  {
    name: "Restaurant Safety and Security Plan Effectiveness",
    description: `"•	Ensures that safety and security programs are in place in the restaurant
    •	Proper dissemination and implementation"`,
  },
  {
    name: "RTMs Recruitment",
    description: `"•	Hiring and selection of RTMs & SMs
    •	Ensures quality of hired RTMs is according to set standards"`,
  },
  {
    name: "Sales Building Awareness",
    description: `"•	Defines the restaurant’s trade area, major competitors, and traffic generators and use the same information to maximize sales
    •	Pro-active in sales building awareness (customer preferences, suggestive selling, trade area sensitivity) and communicate the same with the team
    •	Look for potential sales|marketing opportunities within the retail trade area"`,
  },
  {
    name: "Sales Target",
    description: `·         Setting and achievement of Sales Forecasts/targets`,
  },
  {
    name: "Scheduling",
    description: `"•	Prepares management team schedule"`,
  },
  {
    name: "Voice Of Champions|KFC VOICE",
    description: `·         Measures team’s behavioral contribution to restaurant success`,
  },
  {
    name: "Labour Management (Controllable: Direct Labor)",
    description: `"•	Manpower forecasting
    •	RTMs schedule
    •	Proper distribution and utilization of man-hours
    •	Attainment of labor cost target, TPMH and % improvement versus Last Year "`,
  },
  {
    name: "Local Store Marketing|Catering and Bulk Orders",
    description: `"•	Design and implements all LSM programs effectively
    •	Looks for potential sales opportunity within the retail trade area through outside selling"`,
  },
  {
    name: "Morale of RTM",
    description: `"•	Prepares and evaluated programs the will boost RTM morale
    •	Ensures employees benefits and premiums are administered properly and promptly "`,
  },
  { name: "Appraisals and Promotions", description: `` },
  { name: "Cash & Funds Control Program Effectiveness", description: `` },
  { name: "Competence of Management Trainee and RTMs", description: `` },
  {
    name:
      "Compliance to Government as well as Mall Requirements and Regulations",
    description: ``,
  },
  { name: "Conduct Meetings", description: `` },
  { name: "Customer Complaints", description: `` },
  { name: "Disciplinary Actions", description: `` },
  { name: "Employee Benefits", description: `` },
  { name: "Managing the Restaurant P&L", description: `` },
  { name: "New Products and Procedures", description: `` },
  { name: "Overall Restaurant Management|Supervision", description: `` },
  { name: "Overall Store Management Development Program", description: `` },
  { name: "Planning", description: `` },
  { name: "Profit & Cost Effectivity and Efficiency", description: `` },
  { name: "Reports", description: `` },
  {
    name: "Restaurant Operations Compliance Check (ROCC) Programs",
    description: ``,
  },
  {
    name: "Restaurant Safety and Security Plan Effectiveness",
    description: ``,
  },
  { name: "RTMs Recruitment", description: `` },
  { name: "Sales Building Awareness", description: `` },
  { name: "Sales Program Effectiveness", description: `` },
  { name: "Sales Target", description: `` },
  { name: "Scheduling", description: `` },
  { name: "Voice Of Champions|KFC VOICE", description: `` },
];

const jam: { name: string; description: string }[] = [
  {
    name: "Home Delivery Service & Digital Sales System Effectiveness",
    description: `"
    •	Responsible for HD Service and DIgital Sales operations in the restaurant
    •	Ensures that standard HD Service & Digital Sales procedures are implemented
    •	Monitor maintenance of HD tools such as Aggregator tablet
    •	Training and development of restaurant delivery & digital team
    •	Attainment of Delivery & Digital Sales Target and bottom line
    •	Establish the restaurant’s home delivery trade area
    •	Marketing and promotion of HD in the trade area
    •	Conducts section meetings- Digital Captains & SQE Leads
    •	Responsible for Smart Kitchen implementation in the restaurant. Communicates & achieves the goals & targets of Smart Kitchen- HD & OAPU"`
  },
  {
    name: "PROFIT: P&L Controllables:  Labour Management ",
    description: `"
    •	TMs schedule
    •	Proper distribution and utilization of man-hours
    •	Attainment of labor cost target, SPLH, TPMH and % improvement versus Last Year 
    •	We adjust schedule during out shift; ensure all TMs are taking 30-min break & on time punch in/out to avoid OT."`
  },
  {
    name: "SALES:  SMARTY PARTY",
    description: `"
    •	Responsible for the Smarty Party operations in the restaurant
    •	Ensures logistics need for party reservations are properly coordinated
    •	Training and development of Restaurant’s party host and mascot talent
    •	Attainment of Smarty Party Sales targets and increase sales and booking over last year
    •	Marketing and promotion of Smarty party in the restaurant’s trade area
    •	Promote Smarty Party Package; Catering & Bulk Orders and achieves sales targets"`
  },
  {
    name: "PROFIT: Equipment Maintenance and Calibration Plan Effectiveness",
    description: `"
    •	Equipment and preventive maintenance program
    •	Set repairs and maintenance schedule
    •	Coordinates with the concerned department
    •	Ensures cleanliness and good working condition of the equipment
    •	Performs basic equipment calibration and troubleshooting tasks"`
  },
  {
    name: "SALES: LOCAL RESTAURANT MARKETING",
    description: `"
    •	Assist in designing and implements all LRM programs effectively
    •	Looks for potential sales opportunity within the retail trade area through outside selling"`
  }
];

const agm: { name: string; description: string }[] = [
  {
    name: "SALES:  Home Delivery Service & Digital Sales System Effectiveness",
    description: `"
    •	Responsible for HD Service and DIgital Sales operations in the restaurant
    •	Ensures that standard HD Service & Digital Sales procedures are implemented
    •	Monitor maintenance of HD tools such as Aggregator tablet
    •	Training and development of restaurant delivery & digital team
    •	Attainment of Delivery & Digital Sales Target and bottom line
    •	Establish the restaurant’s home delivery trade area
    •	Marketing and promotion of HD in the trade area
    •	Conducts section meetings- Digital Captains & SQE Leads
    •	Responsible for Smart Kitchen implementation in the restaurant. Communicates & achieves the goals & targets of Smart Kitchen- HD & OAPU"`
  },
  {
    name: "PROFIT:  Profit and Cost Effectivity and Efficiency",
    description: `"
    •	Assists the RGM to project and attain monthly P&L budget accurately
    •	Ability to read, understand, and analyze P&L
    •	Can prepare an action plan to improve next month’s P&L
    •	Monitors product movement and efficiencies (Theoretical versus Actual)
    •	Design and execute a plan on improving gap efficiencies
    •	Creates programs to reduce cost
    •	Effectively manage productivity and efficiency in-store operation"`
  },
  {
    name: "SALES: LOCAL RESTAURANT MARKETING | CATERING & BULK ORDERS",
    description: `"
    •	Assist in designing and implements all LRM programs effectively
    •	Looks for potential sales opportunity within the retail trade area through outside selling"`
  },
  {
    name: "RESTAURANT MANAGEMENT: Maintains Restaurant Operations in the Absence of the RGM",
    description: `"
    •	Recruitment and Orientation of RTMs
    •	Manages Team Member training program including Learning Zone Management
    •	Satisfactorily conducts one-on-one with the RTMs
    •	Discipline RTMs in accordance with HR Policies
    •	Plans|Scheudules|Completes RTMs performance evaluation (Observation Checklist|OCL|MOT)
    •	Submits comments for the PMS of all management team members
    •	Identifies RTMs for promotion
    •	Assist Management recruitment through ore-hiring activities
    •	Conduct a management team meeting to review the previous month’s performance and P&L line items
    •	Implementation of Management Team’s Development Plan
    •	Trains the Management Trainee on basic equipment calibration and maintenance
    •	Trains crew trainers 
    •	Assist RGM on the roll-out of new products and procedures
    •	Implementation of market-wide promotions
    •	Review labor hours regularly
    •	Holds controllable P&L line items within the budget
    •	Reconciles monthly P&L with Area Coach
    •	Accomplish monthly store operating reports
    •	Verifies all the deposits (correct amount and on time) have been received by the bank
    •	Reconcile reports with Accounting
    •	Assist in the projection of annual sales and profit plan
    •	Works with the RGM in achieving long-term objectives and action plan for  GES, ROCC, Sales, Profit, and People Development
    •	Accomplish other tasks assigned to him|her time to time"`
  }
];
const sm: { name: string; description: string }[] = [
  {
    name: "PEOPLE: People Management",
    description: `
    •	Manages Team Member training program including E-Learning Management
    •	Satisfactorily conducts one-on-one with the TMs
    •	Discipline TMs in accordance with HR Policies
    •	Plans|Schedules|Completes TMs performance evaluation (Observation Checklist|OCL|MOT)
    •	Identifies TMs for promotion
    •	Trains the TMs on basic equipment calibration and maintenance
    •	Trains crew trainers 
    •	Assist RGM on the roll-out of new products and procedures
    •	Implementation of window promotions
    •	Training of newly hired TM (trainee)
    •	Knowledgeable on the Team Member Training and All-Star Certification`
  },
  {
    name: "PROFIT: Inventory Management",
    description: `"
    •	Effective implementation of Clearview function for forecasting
    •	Mini-max projection level
    •	Plan restaurant build-to for ordering (food, paper and other supplies) and adjustments
    •	Monitors product movement and efficiencies (Theoretical versus Actual)
    •	Assisit in designing and execute a plan on improving gap efficiencies
    •	Ensure strict compliance of the control measures
    •	We are responsible in checking the completeness of received Dunrite & Sysco deliveries. 
    •	Ensure to put them in correct storage. 
    •	Ensuring good product efficiency by encoding on time and accurate daily inventory.
    •	Attainment of food, packaging, restaurant and office supplies cost target"`
  },
  {
    name: "PROFIT: P&L Controllables:  Labour Management",
    description: `"
    •	TMs schedule
    •	Proper distribution and utilization of man-hours
    •	Attainment of labor cost target, SPLH, TPMH and % improvement versus Last Year 
    •	We adjust schedule during out shift; ensure all TMs are taking 30-min break & on time punch in/out to avoid OT."`
  }
];

const kra: { [key: string]: { name: string; description: string }[] } = {
  rgm,
  jam,
  agm,
  sm,
};

export default kra;
