import React, { useState, useEffect } from "react";
import accounting from "accounting";
import Skeleton from "react-loading-skeleton";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardHeader,
  Table,
  Popover,
  PopoverBody,
  Modal,
  FormGroup,
  InputGroup,
} from "reactstrap";
import { useMst } from "store/RootStore";
import { useHistory } from "react-router";
import { RateType } from "types";
import { toast } from "react-toastify";
import config from "../../../../config";
import { getRateDescription } from "utils/helpers";
import classNames from "classnames";

const ApprovedList: React.FC = () => {
  const store = useMst();
  const history = useHistory();
  const [pageLoading, setPageLoading] = useState(false);
  const [selectedRate, setSelectedRate] = useState<RateType | null>(null);
  const [selectedResendEmail, setSelectedResendEmail] = useState("");
  const [isResendEmailModalVisible, setIsResendEmailModalVisible] =
    useState(false);
  const [sendingEmail, setSendingEmail] = useState(false);
  const [selectedYear, setSelectedYear] = useState("2024");
  const [selectedPeriod, setSelectedPeriod] = useState("1");

  const [rates, setRates] = useState<RateType[]>([]);

  useEffect(() => {
    setPageLoading(true);

    store.userAccount
      .getForDiscussionRates(selectedYear, selectedPeriod)
      .then((resp) => resp.data.data)
      .then((data) => setRates(data))
      .then(() => setPageLoading(false));
  }, [selectedYear, selectedPeriod]);

  const handleShowResendEmail = (rate: RateType) => {
    setIsResendEmailModalVisible(true);
    setSelectedRate(rate);
    setSelectedResendEmail(rate.rateeUser?.email ?? "");
  };

  const handleSendEmail = async () => {
    try {
      setSendingEmail(true);
      await store.userAccount.resendEmailRate(
        selectedResendEmail,
        selectedRate
      );
      toast.success("Email sent.");
      setIsResendEmailModalVisible(false);
    } catch (e) {
      console.log(e);
      toast.error("Sorry failed to resend email, please try again later");
    }
    setSendingEmail(false);
  };

  return (
    <>
      <div className="header bg-default pb-6">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col lg="6" xs="7">
                <h6 className="h2 text-white d-inline-block mb-0">
                  For discussion
                </h6>
              </Col>
            </Row>
          </div>
        </Container>
      </div>

      <Container className="mt--6" fluid>
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <h5 className="h3 mb-0"></h5>
                <Row>
                  <Col lg="6" xs="7">
                    <FormGroup>
                      <label className="form-control-label">Year</label>
                      <InputGroup className={classNames("input-group-merge")}>
                        <select
                          className="form-control"
                          value={selectedYear}
                          onChange={(e) => setSelectedYear(e.target.value)}
                        >
                          <option value="2024">2024</option>
                          <option value="2023">2023</option>
                          <option value="2022">2022</option>
                          <option value="2021">2021</option>
                          <option value="2020">2020</option>
                        </select>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col lg="6" xs="7">
                    <FormGroup>
                      <label className="form-control-label">Period</label>
                      <InputGroup className={classNames("input-group-merge")}>
                        <select
                          className="form-control"
                          value={selectedPeriod}
                          onChange={(e) => setSelectedPeriod(e.target.value)}
                        >
                          <option value="1">First</option>
                          <option value="2">Second</option>
                        </select>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
              </CardHeader>

              <CardBody>
                {pageLoading ? (
                  <Skeleton count={5}></Skeleton>
                ) : rates.length ? (
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th className="sort" data-sort="name" scope="col">
                          Year
                        </th>
                        <th className="sort" data-sort="name" scope="col">
                          Period
                        </th>
                        <th className="sort" data-sort="name" scope="col">
                          Rater
                        </th>
                        <th className="sort" data-sort="name" scope="col">
                          Employee name
                        </th>
                        <th className="sort" data-sort="name" scope="col">
                          Form Template
                        </th>
                        <th className="sort" data-sort="name" scope="col">
                          Group details
                        </th>
                        <th className="sort" data-sort="name" scope="col">
                          Overall Score
                        </th>
                        <th className="sort" data-sort="name" scope="col">
                          Overall Rating
                        </th>
                        <th className="sort" data-sort="name" scope="col">
                          Status
                        </th>
                        <th scope="col" />
                      </tr>
                    </thead>
                    <tbody className="list">
                      {rates.map((rate) => {
                        return (
                          <tr key={rate.uuid}>
                            <td>{rate.year}</td>
                            <td>
                              {rate.period == "1"
                                ? "First Period"
                                : "Second Period"}
                            </td>
                            <td>
                              {rate.rater?.profile?.firstName}{" "}
                              {rate.rater?.profile?.lastName}
                            </td>
                            <td>
                              {rate.rateeUser?.profile?.firstName}{" "}
                              {rate.rateeUser?.profile?.lastName}
                            </td>
                            <td>{rate.name}</td>
                            <td>
                              <Table size="sm" borderless>
                                {rate.groups.map((group, index) => (
                                  <tr key={index}>
                                    <td>
                                      {group.name} (
                                      {accounting.formatNumber(group.weight)}%)
                                      -{" "}
                                    </td>
                                    <td>
                                      <b>{group.totalScore}</b>
                                    </td>
                                  </tr>
                                ))}
                              </Table>
                            </td>
                            <td>
                              <b>{rate.totalScore}</b>
                            </td>
                            <td>
                              <b>{getRateDescription(rate.totalScore)}</b>
                            </td>
                            <td>{config.rateStatusesLabels[rate.status]}</td>
                            <td>
                              <Button
                                className="btn-default"
                                onClick={(e) =>
                                  history.push(
                                    `/admin/rate_employee/${rate.uuid}/details`
                                  )
                                }
                                size="sm"
                              >
                                View
                              </Button>
                              <p className="my-3 d-block"></p>

                              <Button
                                className="btn-success"
                                onClick={(e) => handleShowResendEmail(rate)}
                                size="sm"
                              >
                                Resend
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                ) : (
                  <div
                    className="d-flex justify-content-center flex-column align-items-center"
                    style={{ height: 500 }}
                  >
                    <img
                      style={{ width: 300 }}
                      className="mb-5"
                      src="https://repairs-and-maintenance-public.s3-ap-southeast-1.amazonaws.com/undraw_forms_78yw.svg"
                    />
                    <h2 className="mb-2">No rates to display.</h2>
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal
        style={{ minHeight: 300 }}
        className="modal-dialog-centered"
        isOpen={isResendEmailModalVisible}
      >
        <div className="modal-body">
          <Row>
            <Col xl="12">
              <h2>
                {
                  "You are about to re-send the email containing the rate details for discussion to"
                }
              </h2>
              <FormGroup>
                <label className="form-control-label">Email</label>
                <InputGroup className="input-group-merge">
                  <input
                    type="text"
                    className="form-control"
                    value={selectedResendEmail}
                    onChange={(e) => setSelectedResendEmail(e.target.value)}
                  />
                  <small className="mt-1">
                    If you think that this email is wrong, please change it
                    before you click send
                  </small>
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
          <button
            className="btn btn-default"
            disabled={sendingEmail}
            onClick={() => handleSendEmail()}
          >
            {sendingEmail ? "Sending..." : "Send email"}
          </button>
        </div>
      </Modal>
    </>
  );
};

export default ApprovedList;
