import React from "react";
import {
  Controller,
  useForm,
  useFieldArray,
  FormProvider,
  appendErrors,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import { ToastContainer, toast } from "react-toastify";
import schema from "./schema";

import classnames from "classnames";

import {
  Row,
  Container,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  Input,
} from "reactstrap";
import FormTemplateGroup from "./components/FormTemplateGroup";

import { FormTemplateInput } from "../../../../types";
import { useMst } from "store/RootStore";
import { useHistory } from "react-router";

const CreateForm: React.FC = () => {
  const store = useMst();
  const history = useHistory();
  const methods = useForm<FormTemplateInput>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      groups: [
        {
          name: "",
          subtitle: "",
          subitemLabel: "",
          items: [
            {
              name: "",
              weight: 0,
              maxScore: 5,
            },
          ],
        },
      ],
    },
  });

  const control = methods.control;

  const { fields, append, prepend, remove } = useFieldArray({
    control,
    name: "groups",
  });

  const handleRemove = (index: number): void => {
    remove(index);
  };

  console.log(methods.errors);

  const errors = methods.errors;

  const onSubmit = async (values: FormTemplateInput): Promise<void> => {
    try {
      await store.userAccount.saveForm(values);
      toast("Successfully saved form template");
      history.push("/admin/forms/list");
    } catch (e) {
      toast.error("An error occurred while saving your form");
    }
  };

  return (
    <>
      <div className="header bg-default pb-6">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col lg="6" xs="7">
                <h6 className="h2 text-white d-inline-block mb-0">
                  Create Form
                </h6>
              </Col>
              <Col className="text-right" lg="6" xs="5"></Col>
            </Row>
          </div>
        </Container>
      </div>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Container className="mt--6" fluid>
            <Row>
              <Col xl="8">
                <Card>
                  <CardHeader>
                    <h5 className="h3 mb-0">Form template details</h5>
                  </CardHeader>
                  <CardBody>
                    <FormGroup>
                      <label className="form-control-label">Name</label>
                      <InputGroup className={classnames("input-group-merge")}>
                        <Controller
                          name="name"
                          control={control}
                          as={
                            <Input
                              invalid={!!errors.name}
                              placeholder="e.g. PMS for RSC"
                              type="text"
                            />
                          }
                        />
                      </InputGroup>
                      {errors["name"] ? (
                        <span className="text-danger mt-3 d-block">
                          {errors["name"].message}
                        </span>
                      ) : null}
                    </FormGroup>
                  </CardBody>
                </Card>

                {fields.map((item, index) => {
                  return (
                    <FormTemplateGroup
                      key={item.id}
                      index={index}
                      onRemove={() => handleRemove(index)}
                    />
                  );
                })}

                <Card>
                  <CardBody>
                    <a
                      href="javascript:;"
                      className="btn btn-block btn-default"
                      onClick={() =>
                        append({
                          name: "",
                          subtitle: "",
                          subitemLabel: "",
                          items: [
                            {
                              name: "",
                              weight: 0,
                              maxScore: 5,
                            },
                          ],
                        })
                      }
                    >
                      Add group
                    </a>
                  </CardBody>
                </Card>
              </Col>

              <Col xl="4" l="12">
                <Card>
                  <CardHeader>
                    <h5 className="h3 mb-0">Actions</h5>
                  </CardHeader>

                  <CardBody>
                    <Button
                      type="submit"
                      htmlType="submit"
                      className="btn-block btn-default"
                    >
                      Save
                    </Button>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </form>
      </FormProvider>
    </>
  );
};

export default CreateForm;
