import React, { useState, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import {
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  PopoverBody,
  UncontrolledPopover,
  CardHeader,
  Table,
} from "reactstrap";
import { useMst } from "store/RootStore";
import { FormTemplateHeaderType } from "types";
import { useHistory } from "react-router";
import { toast } from "react-toastify";

const FormsList: React.FC = () => {
  const store = useMst();
  const history = useHistory();
  const [pageLoading, setPageLoading] = useState(false);
  const [formTemplates, setFormTemplates] = useState<FormTemplateHeaderType[]>(
    []
  );

  useEffect(() => {
    setPageLoading(true);
    store.userAccount
      .getMyForms()
      .then((response) => {
        setFormTemplates(response.data.data);
      })
      .catch((e) => console.log(e))
      .then(() => setPageLoading(false));
  }, []);

  const handleDelete = async (uuid: string): Promise<void> => {
    try {
      await store.userAccount.deleteForm(uuid);
      toast.success("Successfully deleted");
      window.location.reload();
    } catch (e) {
      toast.success("Error while trying to delete your form");
    }
  };

  const closeDeletePopover = (uuid: string): void => {
    document.getElementById(`btn-delete-${uuid}`)?.click();
  };

  return (
    <>
      <div className="header bg-default pb-6">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col lg="6" xs="7">
                <h6 className="h2 text-white d-inline-block mb-0">My Forms</h6>
              </Col>
              <Col className="text-right" lg="6" xs="5">
                <Button
                  className="btn-neutral"
                  color="default"
                  onClick={(e) => e.preventDefault()}
                  size="sm"
                >
                  New
                </Button>
              </Col>
            </Row>
          </div>
        </Container>
      </div>

      <Container className="mt--6" fluid>
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <h5 className="h3 mb-0"></h5>
              </CardHeader>

              <CardBody>
                {pageLoading ? (
                  <Skeleton count={5}></Skeleton>
                ) : (
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th className="sort" data-sort="name" scope="col">
                          Name
                        </th>
                        <th scope="col" />
                      </tr>
                    </thead>
                    <tbody className="list">
                      {formTemplates.map((template) => {
                        return (
                          <tr key={template.uuid}>
                            <td>{template.name}</td>
                            <td className="text-right">
                              {/* <Button
                                className="btn-default"
                                onClick={(e) =>
                                  history.push(
                                    `/admin/forms/${template.uuid}/edit`
                                  )
                                }
                                size="sm"
                              >
                                Edit
                              </Button>
                              <Button
                                className="btn-primary"
                                onClick={(e) => e.preventDefault()}
                                size="sm"
                              >
                                Share
                              </Button>
                              <span className="mx-3">|</span>

                              <UncontrolledPopover
                                placement="top"
                                target={`btn-delete-${template.uuid}`}
                                className="popover-default"
                              >
                                <PopoverBody>
                                  <p className="text-dark font-weight-bold">
                                    Are you sure you want to delete this form?
                                  </p>
                                  <Button
                                    className="btn btn-danger"
                                    size="sm"
                                    onClick={() => handleDelete(template.uuid)}
                                  >
                                    Yes, delete
                                  </Button>
                                  <Button
                                    className="btn btn-primary"
                                    size="sm"
                                    onClick={() =>
                                      closeDeletePopover(template.uuid)
                                    }
                                  >
                                    No, cancel
                                  </Button>
                                </PopoverBody>
                              </UncontrolledPopover>

                              <Button
                                id={`btn-delete-${template.uuid}`}
                                className="btn-danger"
                                size="sm"
                              >
                                Delete
                              </Button> */}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default FormsList;
