import {
  types,
  applySnapshot,
  cast,
  IAnyModelType,
  Instance,
} from "mobx-state-tree";

export const UserProfile = types
  .model({
    userId: types.number,
    employeeNumber: types.string,
    firstName: types.string,
    lastName: types.string,
    dateHired: types.maybeNull(types.string),
    position: types.maybeNull(types.string),
    positionCode: types.maybeNull(types.string),
    storeNumber: types.maybeNull(types.string),
    division: types.maybeNull(types.string),
    department: types.maybeNull(types.string),
    pmsActive: types.optional(types.boolean, true),
    rater: types.maybe(types.late((): IAnyModelType => UserProfile)),
  })
  .views((self) => ({
    get raterProp() {
      return self.rater as Instance<typeof UserProfile>;
    },
  }));

export const User = types
  .model({
    id: types.number,
    email: types.maybeNull(types.string),
    firstName: types.maybeNull(types.string),
    middleName: types.maybeNull(types.string),
    lastName: types.maybeNull(types.string),
    role: types.optional(types.number, 0),
    isApprover: types.optional(types.boolean, false),
    profile: types.maybeNull(UserProfile),
  })
  .views((self) => ({
    get fullName() {
      return `${self.firstName} ${self.lastName ?? ""}`;
    },
  }));
