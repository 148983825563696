import React, { useState, useEffect } from "react";
import accounting from "accounting";
import Skeleton from "react-loading-skeleton";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardHeader,
  Table,
  UncontrolledPopover,
  PopoverBody,
} from "reactstrap";
import { useMst } from "store/RootStore";
import { useHistory } from "react-router";
import { RateType } from "types";
import { toast } from "react-toastify";
import config from "../../../../config";
import { getRateDescription } from "utils/helpers";

const MySelfRatesList: React.FC = () => {
  const store = useMst();
  const history = useHistory();
  const [pageLoading, setPageLoading] = useState(false);

  const [rates, setRates] = useState<RateType[]>([]);

  useEffect(() => {
    setPageLoading(true);

    store.userAccount
      .getMySelfRatesList()
      .then((resp) => resp.data.data)
      .then((data) => setRates(data))
      .then(() => setPageLoading(false));
  }, []);

  const handleDelete = async (uuid: string) => {
    try {
      await store.userAccount.deleteRate(uuid);
      window.location.reload();
    } catch (e) {
      toast.success("Error while trying to delete your rate");
    }
  };

  return (
    <>
      <div className="header bg-default pb-6">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col lg="6" xs="7">
                <h6 className="h2 text-white d-inline-block mb-0">
                  My Self Ratings
                </h6>
              </Col>
            </Row>
          </div>
        </Container>
      </div>

      <Container className="mt--6" fluid>
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <h5 className="h3 mb-0"></h5>
              </CardHeader>

              <CardBody>
                {pageLoading ? (
                  <Skeleton count={5}></Skeleton>
                ) : rates.length ? (
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th className="sort" data-sort="name" scope="col">
                          Year
                        </th>
                        <th className="sort" data-sort="name" scope="col">
                          Period
                        </th>
                        <th
                          className="sort"
                          data-sort="name"
                          scope="col"
                          style={{ width: 200 }}
                        >
                          Form Template
                        </th>
                        <th
                          className="sort"
                          data-sort="name"
                          scope="col"
                          style={{ width: 340 }}
                        >
                          Group details
                        </th>
                        <th className="sort" data-sort="name" scope="col">
                          Overall Score
                        </th>
                        <th className="sort" data-sort="name" scope="col">
                          Overall Rating
                        </th>
                        <th className="sort" data-sort="name" scope="col">
                          Status
                        </th>
                        <th scope="col" />
                      </tr>
                    </thead>
                    <tbody className="list">
                      {rates.map((rate) => {
                        return (
                          <tr key={rate.uuid}>
                            <td>{rate.year}</td>
                            <td>
                              {rate.period == "1"
                                ? "First Period"
                                : "Second Period"}
                            </td>
                            <td>{rate.name}</td>
                            <td>
                              <Table size="sm" borderless>
                                {rate.groups.map((group, index) => (
                                  <tr key={index}>
                                    <td>
                                      {group.name}(
                                      {accounting.formatNumber(group.weight)}%)
                                    </td>
                                    <td>
                                      <b>{group.totalScore}</b>
                                    </td>
                                  </tr>
                                ))}
                              </Table>
                            </td>
                            <td>
                              <b>{rate.totalScore}</b>
                            </td>
                            <td>
                              <b>{getRateDescription(rate.totalScore)}</b>
                            </td>
                            <td>{config.rateStatusesLabels[rate.status]}</td>
                            <td>
                              <Button
                                className="btn-primary"
                                onClick={(e) =>
                                  history.push(
                                    `/admin/rate_employee/${rate.uuid}/details`
                                  )
                                }
                                size="sm"
                              >
                                View
                              </Button>
                              <Button
                                className="btn-default"
                                onClick={(e) =>
                                  history.push(
                                    `/admin/self_rates/${rate.uuid}/edit`
                                  )
                                }
                                size="sm"
                              >
                                Edit
                              </Button>
                              <span className="mx-3">|</span>

                              <UncontrolledPopover
                                placement="top"
                                target={`btn-delete-${rate.uuid}`}
                                className="popover-default"
                              >
                                <PopoverBody>
                                  <p className="text-dark font-weight-bold">
                                    Are you sure you want to delete this form?
                                  </p>
                                  <Button
                                    className="btn btn-danger"
                                    size="sm"
                                    onClick={() =>
                                      rate.uuid && handleDelete(rate.uuid)
                                    }
                                  >
                                    Yes, delete
                                  </Button>
                                  <Button
                                    className="btn btn-primary"
                                    size="sm"
                                    // onClick={() =>
                                    //   closeDeletePopover(rate.uuid)
                                    // }
                                  >
                                    No, cancel
                                  </Button>
                                </PopoverBody>
                              </UncontrolledPopover>

                              <Button
                                id={`btn-delete-${rate.uuid}`}
                                className="btn-danger"
                                size="sm"
                              >
                                Delete
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                ) : (
                  <div
                    className="d-flex justify-content-center flex-column align-items-center"
                    style={{ height: 500 }}
                  >
                    <img
                      style={{ width: 300 }}
                      className="mb-5"
                      src="https://repairs-and-maintenance-public.s3-ap-southeast-1.amazonaws.com/undraw_no_data_qbuo.svg"
                    />
                    <h2 className="mb-2">
                      You haven't created any self-rates yet.
                    </h2>
                    <button
                      className="btn btn-primary"
                      onClick={() => history.push("/admin/self_rates/create")}
                    >
                      Rate yourself now
                    </button>
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default MySelfRatesList;
