import * as yup from "yup";
import { values } from "lodash";

const schema = yup.object().shape({
  name: yup.string().required("Form template name is required"),
  groups: yup.array().of(
    yup.object().shape({
      name: yup.string().required("Group name is required"),
      weight: yup.number().required("Weight is required"),
      subtitle: yup.string(),
      subitemLabel: yup.string(),
      items: yup.array().of(
        yup.object().shape({
          name: yup.string().required("Item name is required"),
          weight: yup.number().required("Weight is required"),
          maxScore: yup.number().required("Max score is required"),
          subitems: yup.array().of(
            yup.object().shape({
              name: yup.string().required("Subitem is required"),
            })
          ),
          subitemsScoreChoices: yup.array().when("subitems", {
            is: (values) => values && values.length,
            then: yup
              .array()
              .of(
                yup.object().shape({
                  name: yup.string().required("Label is required"),
                  score: yup.string().required("Score is required"),
                })
              )
              .required("You must define subitem score choices"),
            otherwise: yup.array(),
          }),
        })
      ),
    })
  ),
});

export default schema;
