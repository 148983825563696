import React, { useState, useEffect } from "react";
import accounting from "accounting";
import Skeleton from "react-loading-skeleton";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardHeader,
  Table,
  Popover,
  PopoverBody,
  InputGroup,
  FormGroup,
} from "reactstrap";
import { useMst } from "store/RootStore";
import { useHistory } from "react-router";
import { RateType } from "types";
import { toast } from "react-toastify";
import config from "../../../../config";
import { getRateDescription } from "utils/helpers";
import classNames from "classnames";

const AcknowledgedList: React.FC = () => {
  const store = useMst();
  const history = useHistory();
  const [pageLoading, setPageLoading] = useState(false);
  const [selectedYear, setSelectedYear] = useState("2023");
  const [selectedPeriod, setSelectedPeriod] = useState("1");

  const [rates, setRates] = useState<RateType[]>([]);

  useEffect(() => {
    setPageLoading(true);

    store.userAccount
      .getAcknowledgedRates(selectedYear, selectedPeriod)
      .then((resp) => resp.data.data)
      .then((data) => setRates(data))
      .then(() => setPageLoading(false));
  }, [selectedYear, selectedPeriod]);

  return (
    <>
      <div className="header bg-default pb-6">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col lg="6" xs="7">
                <h6 className="h2 text-white d-inline-block mb-0">
                  Acknowledged
                </h6>
              </Col>
            </Row>
          </div>
        </Container>
      </div>

      <Container className="mt--6" fluid>
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <h5 className="h3 mb-0"></h5>
                <Row className="align-items-center py-4">
                  <Col lg="6" xs="7">
                    <FormGroup>
                      <label className="form-control-label">Year</label>
                      <InputGroup className={classNames("input-group-merge")}>
                        <select
                          className="form-control"
                          value={selectedYear}
                          onChange={(e) => setSelectedYear(e.target.value)}
                        >
                          <option value="2024">2024</option>
                          <option value="2023">2023</option>
                          <option value="2022">2022</option>
                          <option value="2021">2021</option>
                          <option value="2020">2020</option>
                        </select>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col lg="6" xs="7">
                    <FormGroup>
                      <label className="form-control-label">Period</label>
                      <InputGroup className={classNames("input-group-merge")}>
                        <select
                          className="form-control"
                          value={selectedPeriod}
                          onChange={(e) => setSelectedPeriod(e.target.value)}
                        >
                          <option value="1">First</option>
                          <option value="2">Second</option>
                        </select>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
              </CardHeader>

              <CardBody>
                {pageLoading ? (
                  <Skeleton count={5}></Skeleton>
                ) : rates.length ? (
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th className="sort" data-sort="name" scope="col">
                          Year
                        </th>
                        <th className="sort" data-sort="name" scope="col">
                          Period
                        </th>
                        <th className="sort" data-sort="name" scope="col">
                          Rater
                        </th>
                        <th className="sort" data-sort="name" scope="col">
                          Employee name
                        </th>
                        <th className="sort" data-sort="name" scope="col">
                          Form Template
                        </th>
                        <th className="sort" data-sort="name" scope="col">
                          Group details
                        </th>
                        <th className="sort" data-sort="name" scope="col">
                          Overall Score
                        </th>
                        <th className="sort" data-sort="name" scope="col">
                          Overall Rating
                        </th>
                        <th className="sort" data-sort="name" scope="col">
                          Status
                        </th>
                        <th scope="col" />
                      </tr>
                    </thead>
                    <tbody className="list">
                      {rates.map((rate) => {
                        return (
                          <tr key={rate.uuid}>
                            <td>{rate.year}</td>
                            <td>
                              {rate.period == "1"
                                ? "First Period"
                                : "Second Period"}
                            </td>
                            <td>
                              {rate.rater?.profile?.firstName}{" "}
                              {rate.rater?.profile?.lastName}
                            </td>
                            <td>
                              {rate.rateeUser?.profile?.firstName}{" "}
                              {rate.rateeUser?.profile?.lastName}
                            </td>
                            <td>{rate.name}</td>
                            <td>
                              {rate.groups.map((group) => (
                                <div key={group.name}>
                                  {group.name} (
                                  {accounting.formatNumber(group.weight)}%) -{" "}
                                  <b>{group.totalScore}</b>
                                </div>
                              ))}
                            </td>
                            <td>
                              <b>{rate.totalScore}</b>
                            </td>
                            <td>
                              <b>{getRateDescription(rate.totalScore)}</b>
                            </td>
                            <td>{config.rateStatusesLabels[rate.status]}</td>
                            <td>
                              <Button
                                className="btn-default"
                                onClick={(e) =>
                                  history.push(
                                    `/admin/rate_employee/${rate.uuid}/details`
                                  )
                                }
                                size="sm"
                              >
                                View
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                ) : (
                  <div
                    className="d-flex justify-content-center flex-column align-items-center"
                    style={{ height: 500 }}
                  >
                    <img
                      style={{ width: 300 }}
                      className="mb-5"
                      src="https://repairs-and-maintenance-public.s3-ap-southeast-1.amazonaws.com/undraw_forms_78yw.svg"
                    />
                    <h2 className="mb-2">No rates to display.</h2>
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AcknowledgedList;
