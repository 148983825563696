import React from "react";

const Loading: React.FC = () => {
  return (
    <div
      className="bg-default w-100 text-white d-flex justify-content-center align-items-center"
      style={{ height: "100vh" }}
    >
      Loading...
    </div>
  );
};

export default Loading;
